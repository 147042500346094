type Props = {
  className?: string;
};

const ZoomInIcon: React.FC<Props> = ({ className }) => (
  <svg
    className={className}
    width="1em"
    height="1em"
    viewBox="0 0 22 22"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M20.9941 20.7602L16.5081 16.2662M10.4941 6.76019V10.2602M10.4941 10.2602V13.7602M10.4941 10.2602H13.9941M10.4941 10.2602H6.99414M18.9941 10.2602C18.9941 12.5145 18.0986 14.6765 16.5045 16.2706C14.9105 17.8647 12.7485 18.7602 10.4941 18.7602C8.2398 18.7602 6.07779 17.8647 4.48373 16.2706C2.88967 14.6765 1.99414 12.5145 1.99414 10.2602C1.99414 8.00585 2.88967 5.84384 4.48373 4.24979C6.07779 2.65573 8.2398 1.76019 10.4941 1.76019C12.7485 1.76019 14.9105 2.65573 16.5045 4.24979C18.0986 5.84384 18.9941 8.00585 18.9941 10.2602V10.2602Z"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
    />
  </svg>
);

export default ZoomInIcon;
