import { isEmpty } from 'lodash';
import getHeadingTag from 'helpers/getHeadingTag';
import getHeadingSkin from 'helpers/getHeadingSkin';
import { Heading } from 'components/atoms/Heading';
import { RichText } from 'components/atoms/RichText';
import Container from 'components/layout/Grid/Container';
import { Breadcrumb } from 'components/molecules/Breadcrumb';
import { Box, ImageColumn, PictureStyled, Subheadline } from './Hero.shared.styles';
import { HeroShareButtons } from './partials/HeroShareButtons';
import { DateTimeInfo } from './partials/DateTimeInfo';
import { HeroProps } from './Hero.types';
import { DateContainer } from './HeroCourse.styles';

const ImpliedDash = () => <span className="visually-hidden">{' – '}</span>;

export const Hero: React.FC<HeroProps> = ({
  className,
  children,
  primary: {
    headline,
    subheadline,
    intro_text,
    image,
    big_image,
    show_breadcrumb,
    show_social_sharing,
    times,
  },
}) => {
  const hasImage = !isEmpty(image);
  const hasIntroText = (intro_text?.[0]?.text && intro_text?.[0].text.length > 0) ?? false;

  return (
    <Container className={className}>
      <Box
        isBox={hasImage}
        start={1}
        span={hasImage ? { xs: 12, md: 5 } : { xs: 12, md: 7 }}
        row={hasImage ? { xs: 2, md: 1 } : {}}
      >
        {show_breadcrumb && <Breadcrumb />}
        <Heading
          skin={getHeadingSkin(headline?.[0]?.type)}
          elementType={getHeadingTag(headline?.[0]?.type)}
        >
          {!show_breadcrumb && subheadline && (
            <>
              <Subheadline elementType="span" skin="400">
                {subheadline}
              </Subheadline>
              <ImpliedDash />
            </>
          )}
          {headline?.[0]?.text}
        </Heading>
        {times && (
          <DateContainer>
            {times &&
              times.map((date) => {
                return (
                  <DateTimeInfo
                    key={`${date.nextDate}`}
                    date={date.nextDate}
                    timeslot={date.nextTimeslot}
                  />
                );
              })}
          </DateContainer>
        )}
        {hasIntroText && <RichText rich_text={intro_text} />}
        {children && <div>{children}</div>}
        {show_social_sharing && <HeroShareButtons />}
      </Box>
      {hasImage && (
        <ImageColumn
          start={{ xs: 1, md: 5 }}
          span={{ xs: 12, md: 8 }}
          row={{ xs: 1, md: 1 }}
          isBig={big_image}
        >
          <PictureStyled objectFit image={image} />
        </ImageColumn>
      )}
    </Container>
  );
};
