import { css } from '@nx-kit/styling';
import { primary, secondary, tertiaryBig, tertiarySmall, teaser } from './cta';
import { scheduleSliderItem, newsSliderItem } from './slider';

const navLevel1 = css<any>`
  display: block;
  font-weight: 500;
  font-size: 20px;
  line-height: 30px;
  padding: 10px 0;
  margin-right: 50px;
  color: ${({ theme }) => theme.global.color.gray900};

  ${({ isActive, isHovered }) =>
    (isActive || isHovered) &&
    css`
      color: ${({ theme }) => theme.global.color.primary};
    `}
  ${({ isFocused, theme }) => isFocused && theme.global.focusRing};
`;

const navLevel2 = css<any>`
  ${navLevel1}
  font-weight: 400;
`;

const navLevel3 = css<any>`
  ${navLevel2}
  font-size: 16px;
  line-height: 26px;
`;

const navMeta = css<any>`
  font-size: 12px;
  line-height: 20px;
  text-decoration: none;
  color: ${({ theme }) => theme.global.color.white};
  ${({ isActive, isHovered }) =>
    (isActive || isHovered) &&
    css`
      color: ${({ theme }) => theme.global.color.primary};
    `}
  ${({ isFocused, theme }) => isFocused && theme.global.focusRing};
`;

const navMetaMobile = css<any>`
  display: block;
  font-size: 16px;
  line-height: 20px;
  font-weight: 500;
  text-decoration: none;
  color: ${({ theme }) => theme.global.color.gray900};
  ${({ isActive, isHovered }) =>
    (isActive || isHovered) &&
    css`
      color: ${({ theme }) => theme.global.color.primary};
    `}
  ${({ isFocused, theme }) => isFocused && theme.global.focusRing};
`;

// eslint-disable-next-line import/prefer-default-export
export const link = {
  global: css<any>`
    text-decoration: none;
    color: ${({ theme }) => theme.global.color.primary};
    cursor: pointer;
    &:focus {
      outline: none;
    }
  `,
  skin: {
    primary,
    secondary,
    tertiaryBig,
    tertiarySmall,
    navLevel1,
    navLevel2,
    navLevel3,
    navMeta,
    navMetaMobile,
    teaser,
    scheduleSliderItem,
    newsSliderItem,
  },
};
