import { Fragment, useContext } from 'react';
import useTranslation from 'next-translate/useTranslation';
import { useRouter } from 'next/router';
import find from 'lodash/find';
import map from 'lodash/map';
import { LOCALES_MAP } from 'prismic/config';
import linkResolver from 'prismic/helpers/linkResolver';
import { getDisplayNameByUID } from 'prismic/helpers/getDisplayNameByUID';
import { getPathRecursively } from 'prismic/helpers/getPathFromDocumentRelations';
import { DocumentEntry, MaybeDocumentRelations } from 'prismic/getDocumentRelations';
import { DocumentRelationsContext } from 'contexts/documentRelations/DocumentRelationsContext';
import { BreakpointContext } from 'contexts/breakpoint/BreakpointContext';
import { BreadcrumbLinkType, BreadcrumbProps } from './Breadcrumb.types';
import { Arrow, Wrapper } from './Breadcrumb.styles';
import BreadcrumbLink from './partials/BreadcrumbLink';

const getBreadcrumbLinks = ({
  uid,
  lang,
  currentPath,
  documentRelations,
}: {
  uid: string;
  lang: string;
  currentPath: string;
  documentRelations: MaybeDocumentRelations;
}): BreadcrumbLinkType[] => {
  if (!documentRelations) {
    return [];
  }

  const documentRelationsLang = documentRelations[LOCALES_MAP[lang]];
  const documentPathTree =
    find(documentRelationsLang, (entry) => (entry as DocumentEntry).uid === uid) || {};
  const documentPath = getPathRecursively(documentPathTree as DocumentEntry).reverse();

  const generateUrl = (uid: string): string =>
    linkResolver({ link_type: 'Document', uid, lang: LOCALES_MAP[lang] }, documentRelations);

  const result = documentPath.map((entry) => ({
    label: getDisplayNameByUID({ uid: entry, documentRelationsLang }) || entry,
    url: generateUrl(entry) === currentPath.split('?')[0] ? null : generateUrl(entry),
  }));

  return result;
};

export const Breadcrumb: React.FC<BreadcrumbProps> = ({ className }) => {
  const { t, lang } = useTranslation('common');
  const bp = useContext(BreakpointContext);
  const isMobile = bp === 'xs' || bp === 'sm';
  const { asPath } = useRouter();
  const documentRelations = useContext(DocumentRelationsContext);

  const pathAsArray = asPath.split('/');
  const noEmptyStrings = pathAsArray.filter((str) => str !== '');
  const uid = noEmptyStrings[0];

  if (!uid) {
    return null;
  }

  const currentPath = `/${lang}${asPath}`;

  const segments = getBreadcrumbLinks({ uid, lang, currentPath, documentRelations });

  const breadcrumbData = [];

  // Add "Home" link just if root segment item is not "guide"
  if (segments.length > 0 && !segments[0]?.url?.includes('/guide')) {
    breadcrumbData.push({ label: t('links.home'), url: `/${lang}` });
  }

  breadcrumbData.push(...segments);

  if (isMobile) {
    const parentElement =
      breadcrumbData.length === 1 ? breadcrumbData[0] : breadcrumbData[breadcrumbData.length - 2];
    if (!parentElement) {
      return null;
    }
    return (
      <div className={className}>
        <BreadcrumbLink
          url={parentElement && parentElement.url}
          label={`< ${parentElement && parentElement.label}`}
        />
      </div>
    );
  }

  return (
    <Wrapper className={className}>
      {map(breadcrumbData, (link, index) => (
        <Fragment key={`breadcrumb-${index}`}>
          {index > 0 && <Arrow>&gt;</Arrow>}
          <BreadcrumbLink {...link} key={link.label} />
        </Fragment>
      ))}
    </Wrapper>
  );
};
