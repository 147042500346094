type Props = {
  className?: string;
};

const InstagramIcon: React.FC<Props> = ({ className }) => (
  <svg
    className={className}
    width="25px"
    height="25px"
    viewBox="4 4 16 16"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g id="Icon/instagram" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g
        id="icon-FRE-FRE-038_XL_social_stream-instagram"
        transform="translate(4.000000, 4.000000)"
        fill="currentColor"
        fillRule="nonzero"
      >
        <path
          d="M10.667,8 C10.667,9.473 9.473,10.667 8,10.667 C6.527,10.667 5.333,9.474 5.333,8 C5.333,6.527 6.527,5.333 8,5.333 C9.473,5.333 10.667,6.527 10.667,8 Z M14.513,4.768 C14.551,5.611 14.559,5.864 14.559,8 C14.559,10.136 14.551,10.389 14.513,11.233 C14.413,13.383 13.404,14.414 11.234,14.512 C10.39,14.55 10.137,14.559 8,14.559 C5.864,14.559 5.61,14.551 4.768,14.513 C2.594,14.414 1.587,13.381 1.489,11.234 C1.45,10.389 1.441,10.136 1.441,8 C1.441,5.864 1.45,5.611 1.488,4.768 C1.587,2.616 2.597,1.587 4.767,1.489 C5.611,1.45 5.864,1.442 8,1.442 C10.136,1.442 10.39,1.45 11.233,1.488 C13.401,1.587 14.413,2.616 14.513,4.768 Z M12.108,8 C12.108,5.731 10.268,3.892 8,3.892 C5.731,3.892 3.892,5.731 3.892,8 C3.892,10.269 5.732,12.108 8,12.108 C10.269,12.108 12.108,10.269 12.108,8 Z M13.23,3.73 C13.23,3.2 12.8,2.77 12.27,2.77 C11.74,2.77 11.31,3.2 11.31,3.73 C11.31,4.26 11.74,4.69 12.27,4.69 C12.801,4.69 13.23,4.26 13.23,3.73 L13.23,3.73 Z M16,8 C16,5.828 15.991,5.555 15.952,4.702 C15.821,1.8 14.207,0.182 11.299,0.049 C10.445,0.009 10.173,0 8,0 C5.827,0 5.556,0.009 4.702,0.048 C1.796,0.181 0.182,1.793 0.048,4.701 C0.009,5.555 0,5.828 0,8 C0,10.173 0.009,10.445 0.048,11.298 C0.182,14.204 1.794,15.819 4.702,15.952 C5.556,15.991 5.827,16 8,16 C10.173,16 10.445,15.991 11.299,15.952 C14.201,15.819 15.821,14.207 15.952,11.298 C15.991,10.445 16,10.173 16,8 Z"
          id="Shape"
        />
      </g>
    </g>
  </svg>
);

export default InstagramIcon;
