type Props = {
  className?: string;
};

const TeacherCursor: React.FC<Props> = ({ className }) => (
  <svg
    className={className}
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M3.60714 10.072C2.91286 9.69285 2.91286 8.69583 3.60714 8.31668L11.5207 3.99509C11.8194 3.83196 12.1806 3.83196 12.4793 3.99509L20.3929 8.31668C21.0871 8.69582 21.0871 9.69285 20.3929 10.072L12.4793 14.3936C12.1806 14.5567 11.8194 14.5567 11.5207 14.3936L3.60714 10.072Z"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
    />
    <path
      d="M6.31648 12.2392V16.5288C6.31648 16.8946 6.51555 17.2309 6.83657 17.4062C8.49879 18.3139 10.5952 19.4587 11.522 19.9649C11.8207 20.128 12.1806 20.1273 12.4793 19.9642L17.1628 17.4065C17.4838 17.2312 17.6835 16.8946 17.6835 16.5288V12.2392"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
    />
    <circle cx="20.5089" cy="19.8331" r="1.63388" stroke="currentColor" strokeWidth="1.5" />
    <line x1="20.375" y1="10.1475" x2="20.375" y2="18.1475" stroke="#34A4D4" strokeWidth="1.5" />
  </svg>
);

export default TeacherCursor;
