type Props = {
  className?: string;
};

const CheckCircledIcon: React.FC<Props> = ({ className }) => (
  <svg
    className={className}
    width="54"
    height="55"
    viewBox="0 0 54 55"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M17 28.9629L24.5 36.4629L37 21.4629"
      stroke="#11D224"
      strokeWidth="4"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M27 52.7129C40.8071 52.7129 52 41.52 52 27.7129C52 13.9058 40.8071 2.71289 27 2.71289C13.1929 2.71289 2 13.9058 2 27.7129C2 41.52 13.1929 52.7129 27 52.7129Z"
      stroke="#11D224"
      strokeWidth="4"
    />
  </svg>
);

export default CheckCircledIcon;
