import Arrow from './icons/Arrow';
import ArrowIconBold from './icons/ArrowIconBold';
import ArrowIconSlim from './icons/ArrowIconSlim';
import BlogIcon from './icons/BlogIcon';
import CalendarIcon from './icons/CalendarIcon';
import ClockIcon from './icons/ClockIcon';
import DownloadIcon from './icons/DownloadIcon';
import DonateIcon from './icons/DonateIcon';
import DefaultChannel from './icons/DefaultChannel';
import FacebookIcon from './icons/FacebookIcon';
import InstagramIcon from './icons/InstagramIcon';
import LinkedInIcon from './icons/LinkedInIcon';
import MailIcon from './icons/MailIcon';
import MailIconAlternative from './icons/MailIconAlternative';
import MapIcon from './icons/Map';
import MapCursor from './icons/MapCursor';
import PhoneIcon from './icons/PhoneIcon';
import TripAdvisorIcon from './icons/TripAdvisorIcon';
import TwitterIcon from './icons/TwitterIcon';
import WhatsappIcon from './icons/WhatsappIcon';
import XingIcon from './icons/XingIcon';
import YoutubeIcon from './icons/YoutubeIcon';
import CloseIcon from './icons/Close';
import InfoIcon from './icons/InfoIcon';
import CenterMapIcon from './icons/CenterMapIcon';
import ZoomInIcon from './icons/ZoomInIcon';
import ZoomOutIcon from './icons/ZoomOutIcon';
import CheckCircledIcon from './icons/CheckCircledIcon';
import Loupe from './icons/LoupeIcon';
import TeacherCursor from './icons/TeacherCursor';
import QrCode from './icons/QrCodeIcon';

export type CommonIconProps = {
  className?: string;
};

export const IconMap = {
  ARROW: Arrow,
  ARROW_BOLD: ArrowIconBold,
  ARROW_SLIM: ArrowIconSlim,
  BLOG: BlogIcon,
  CALENDAR: CalendarIcon,
  CENTERMAP: CenterMapIcon,
  CHECKCIRCLEDICON: CheckCircledIcon,
  CLOCK: ClockIcon,
  CLOSE: CloseIcon,
  DOWNLOAD: DownloadIcon,
  DONATE: DonateIcon,
  DEFAULTCHANNEL: DefaultChannel,
  FACEBOOK: FacebookIcon,
  INFO: InfoIcon,
  INSTAGRAM: InstagramIcon,
  LINKEDIN: LinkedInIcon,
  LOUPE: Loupe,
  MAIL: MailIcon,
  MAIL_ALTERNATIVE: MailIconAlternative,
  MAP_PLAN: MapIcon,
  MAPCURSOR: MapCursor,
  PHONE: PhoneIcon,
  TEACHER: TeacherCursor,
  TRIP_ADVISOR: TripAdvisorIcon,
  TWITTER: TwitterIcon,
  WHATSAPP: WhatsappIcon,
  XING: XingIcon,
  YOUTUBE: YoutubeIcon,
  ZOOMIN: ZoomInIcon,
  ZOOMOUT: ZoomOutIcon,
  QR_CODE: QrCode,
};

export type IconMapType = typeof IconMap;

export type IconType = keyof IconMapType;
