import { styled } from '@nx-kit/styling';
import { SVGIconProps } from './Icons.types';

const Svg = styled.svg`
  shape-rendering: geometricPrecision;
  stroke-width: 1;
  stroke: currentColor;
  fill: none;

  * {
    vector-effect: non-scaling-stroke;
  }
`;

const Arrow: React.FC<SVGIconProps> = ({ className }) => (
  <Svg className={className} viewBox="0 0 30 8" height="1em">
    <polyline points="0,4 29,4" />
    <polyline strokeLinecap="round" points="26,1 29,4 26,7" />
  </Svg>
);

export default Arrow;
