/* eslint-disable jsx-a11y/alt-text */
import { useContext } from 'react';
import { PrismicResponsiveImage } from 'prismic/types';
import { BreakpointContext } from 'contexts/breakpoint/BreakpointContext';
import getDevice from 'helpers/getDevice';
import { Wrapper } from './Picture.styles';

export interface PictureProps {
  className?: string;
  image: PrismicResponsiveImage;
  noMinHeight?: boolean;
  objectFit?: boolean;
  retinaFactor?: number;
  size?: 'half' | 'full' | 'container';
}

function updateImageParameters(imageData: PrismicResponsiveImage, newParam: string): string {
  const { url } = imageData;

  if (!url) {
    return '';
  }

  const cleanUrl = url.replace(/[?&](w|h|rect)=[^&]+/g, '');
  return `${cleanUrl}${cleanUrl.includes('?') ? '&' : '?'}${newParam}`;
}

const getPictureSize = (size: string) => {
  switch (size) {
    case 'half':
      return {
        desktop: '425',
        laptop: '425',
        tablet: '425',
        smartphone: '425',
      };
    case 'full':
      return {
        desktop: '1600',
        laptop: '1280',
        tablet: '1024',
        smartphone: '425',
      };
    case 'container':
    default:
      return {
        desktop: '1280',
        laptop: '1024',
        tablet: '768',
        smartphone: '425',
      };
  }
};

const Picture: React.FC<PictureProps> = ({
  image,
  className,
  retinaFactor = 1,
  objectFit = false,
  noMinHeight = false,
  size = 'container',
}) => {
  const breakpoint = useContext(BreakpointContext);
  const isTablet = getDevice(breakpoint, 'tablet');
  const isSmartphone = getDevice(breakpoint, 'smartphone');

  const getImageTree = () => {
    switch (true) {
      case isSmartphone:
        return image?.XS ?? image;
      case isTablet:
        return image?.MD ?? image;
      default:
        return image;
    }
  };

  const width = (getImageTree().dimensions?.width ?? 0) / retinaFactor;
  const height = (getImageTree().dimensions?.height ?? 0) / retinaFactor;
  const imageProps = !objectFit
    ? {
        width,
        height,
      }
    : {};
  const imageSize = getPictureSize(size);

  return (
    <Wrapper
      className={className}
      objectFit={objectFit}
      minHeight={height}
      noMinHeight={noMinHeight}
    >
      <picture>
        <source
          srcSet={updateImageParameters(image, `w=${imageSize.desktop}`)}
          media="(min-width: 1440px)"
        />
        <source
          srcSet={updateImageParameters(image, `w=${imageSize.laptop}`)}
          media="(min-width: 1024px)"
        />
        <source
          srcSet={updateImageParameters(image, `w=${imageSize.tablet}`)}
          media="(min-width: 768px)"
        />
        <img
          {...imageProps}
          alt={getImageTree().alt ?? ''}
          loading="lazy"
          src={`${updateImageParameters(image, `w=${imageSize.smartphone}`)}`}
        />
      </picture>
    </Wrapper>
  );
};

export default Picture;
