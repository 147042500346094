import { useContext } from 'react';
import useTranslation from 'next-translate/useTranslation';
import { DomainContext } from 'contexts/domain/DomainContext';
import { ColumnStyled } from 'components/organisms/BlogOverview/BlogOverview.styles';
import CmsElmtWrapper from 'components/organisms/CmsElmtWrapper/CmsElmtWrapper';
import Container from 'components/layout/Grid/Container';
import { Hero } from 'components/organisms/Hero';
import { LinkStyled, LogoStyled, Wrapper, HStyled, AStyled } from './ErrorPageContent.styles';
import { errorT } from './ErrorPageContent.helper';

interface ErrorPageContentProps {
  isRunError?: boolean;
}

export const ErrorPageContent: React.FC<ErrorPageContentProps> = ({ isRunError }) => {
  const {
    computed: { isCourse },
  } = useContext(DomainContext);

  const { lang } = useTranslation('common');
  let translationLabels;

  if (isRunError) {
    translationLabels = errorT(lang)['runError'];
  } else if (isCourse) {
    translationLabels = errorT(lang)['errorPageCourse'];
  } else {
    translationLabels = errorT(lang)['errorPage'];
  }

  if (isRunError) {
    return (
      <Wrapper>
        <LogoStyled />
        <HStyled> {translationLabels['title']}</HStyled>
        <div> {translationLabels['text']}</div>
        <AStyled href={`/${lang}/`}>{translationLabels['buttonBack']}</AStyled>
      </Wrapper>
    );
  }

  const heroPrimary = {
    headline: [
      {
        type: 'heading1',
        text: translationLabels['title'],
        spans: [],
      },
    ],
    image: {
      dimensions: {
        width: 2560,
        height: 1440,
      },
      alt: '',
      url: isCourse
        ? '/error/ballenberg_kurse_404-min.jpg'
        : '/error/ballenberg_museum_404-min.jpg',
    },
    intro_text: [
      {
        type: 'paragraph',
        text: translationLabels['text'],
        spans: [],
      },
    ],
    subheadline: '',
    show_breadcrumb: false,
    show_social_sharing: false,
    big_image: false,
    nextDate: undefined,
    nextTimeslot: undefined,
  };

  return (
    <>
      <Container>
        <ColumnStyled span={12}>
          <LogoStyled />
        </ColumnStyled>
      </Container>
      <CmsElmtWrapper sliceName="hero" nextSlice="default">
        <Hero primary={heroPrimary}>
          <LinkStyled href={`/${lang}/`} skin="primary">
            {translationLabels['buttonBack']}
          </LinkStyled>
        </Hero>
      </CmsElmtWrapper>
    </>
  );
};
