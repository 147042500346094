import { media, css } from '@nx-kit/styling';
import { Theme } from './themeTypes';
import { accordion } from './components/accordion';
import { link } from './components/link';
import { button } from './components/button';
import { checkbox } from './components/checkbox';

const focusRing = css`
  &&& {
    outline: 2px solid ${({ theme }) => theme.global.color.gray900};
    outline-offset: 3px;
  }
`;

export const defaultTheme: Theme = {
  global: {
    color: {
      gray50: '#f5f5f5',
      gray100: '#f0f0f0',
      gray150: '#E5E5E5',
      gray180: '#9A9A9A',
      gray200: '#9B9B9B',
      gray300: '#ACACAC',
      gray500: '#D7D7D7',
      gray600: '#DFDFDF',
      gray650: '#D1D1D1',
      gray900: '#191919',
      white: '#ffffff',
      primary: '#E63312',
      primary500: '#E63312',
      secondary: '#6880A5',
      selection: '#000',
      metaTheme: '#fff',
      beige: '#f9f6f1',
      gold: '#c6a171',
      lightBlue: '#34A4D4',
      statusGreen: '#13D126',
      statusOrange: '#FCBC17',
      statusRed: '#E93030',
    },
    focusRing,
    breakpoint: {
      xs: { min: 0, max: 575.98 },
      sm: { min: 576, max: 767.98 },
      md: { min: 768, max: 991.98 },
      lg: { min: 992, max: 1199.98 },
      xl: { min: 1200, max: 1599.98 },
      xxl: { min: 1600, max: 1919.98 },
      xxxl: { min: 1920, max: null },
    },
    spacing: {
      small: 5,
      medium: 10,
      big: 20,
    },
    font: {
      brandRegular: `
        font-family: DIN, Helvetica, Arial, sans-serif !important;
      `,
    },
    fontSize: {
      small: 13,
      medium: 16,
      big: 20,
    },
    lineHeight: {
      default: 1.625,
      headline: 1.3,
      copytext: 1.625,
    },
    easing: {
      default: 'cubic-bezier(.2,.1,.2,1)',
      sine: 'cubic-bezier(.36,0,.64,1)',
      snappy: 'cubic-bezier(.3,0,0,1)',
    },
    zIndex: {
      minusOne: -1,
      zero: 0,
      one: 1,
      two: 2,
      three: 3,
      sliderNavButtons: 4,
      dropdowns: 5,
      statusCourseBox: 3,
      header: 2000,
      metaBar: 2001,
      ModalSearch: 2002,
      datePicker: 2001,
      guideQrScanButton: 1900,
      guideQrScanModal: 3000,
    },
  },
  component: {
    accordion,
    heading: {
      skin: {
        400: css`
          ${({ theme }) => theme.global.font.brandRegular};
          font-size: 11px;
          line-height: 20px;
          font-weight: 500;
          margin-bottom: 10px;
          ${media('xl')} {
            font-size: 14px;
            line-height: 20px;
          }
        `,
        500: css`
          ${({ theme }) => theme.global.font.brandRegular};
          font-size: 20px;
          line-height: 30px;
          font-weight: 700;
          margin-bottom: 10px;
        `,
        600: css`
          ${({ theme }) => theme.global.font.brandRegular};
          font-size: 24px;
          line-height: 31px;
          font-weight: 700;
          margin-bottom: 13px;
          ${media('xl')} {
            font-size: 28px;
            line-height: 38px;
          }
        `,
        700: css`
          ${({ theme }) => theme.global.font.brandRegular};
          font-size: 28px;
          line-height: 36px;
          font-weight: 700;
          margin-bottom: 20px;
          ${media('xl')} {
            font-size: 32px;
            line-height: 42px;
          }
        `,
        800: css`
          ${({ theme }) => theme.global.font.brandRegular};
          font-size: 32px;
          line-height: 42px;
          letter-spacing: -0.01em;
          font-weight: 700;
          margin-bottom: 20px;
          ${media('xl')} {
            font-size: 46px;
            line-height: 56px;
          }
        `,
      },
    },
    text: {
      skin: {
        400: css`
          font-size: ${({ theme }) => theme.global.fontSize.medium}px;
          line-height: ${({ theme }) => theme.global.lineHeight.copytext};
          p:not(:last-child) {
            margin-bottom: 15px;
          }
        `,
      },
    },
    link,
    button,
    checkbox,
  },
  grid: {
    gap: { xs: 16 },
    sideGap: { xs: 24, md: 60, xl: 120, xxl: 320 },
  },
  spacing: {
    xl: {
      default: 140,
      cmsElements: {
        text: {
          default: 50,
        },
        hero: {
          default: 120,
        },
        teasers: {
          default: 120,
        },
      },
    },
    xs: {
      default: 25,
      cmsElements: {
        text: {
          default: 25,
        },
        hero: {
          default: 60,
        },
        teasers: {
          default: 60,
        },
      },
    },
  },
  list: {
    xs: {
      ul: {
        marginTop: '1em',
        marginBottom: '1em',
        marginLeft: '0.8em',
        listStyle: 'none',
      },
      ol: {
        marginTop: '1em',
        marginBottom: '1em',
        paddingLeft: '3.5em',
        listStyle: 'none',
      },
      li: {
        marginTop: '0.375em',
        marginBottom: '0.375em',
        paddingLeft: '0.64em',
      },
      disc: {
        color: css`
          ${({ theme }) => theme.global.color.primary}
        `,
      },
      counter: {
        fontWeight: '400',
        counterReset: {
          level1: 'doc-counter',
          level2: 'doc-alpha',
        },
      },
    },
  },
};
