import { createGlobalStyle } from '@nx-kit/styling';
import { Theme } from 'themes/themeTypes';
import root from 'helpers/getRootPath';

const fontsPath = `${root}fonts`;

const FontsCSS = createGlobalStyle<{ theme: Theme }>`
    @font-face {
    font-family: DIN;
    font-weight: normal;
    font-style: normal;
    font-display: fallback;
    src: url('${fontsPath}/normal/FFDINRegular.woff2') format("woff2"),
         url('${fontsPath}/normal/FFDINRegular.woff') format("woff");
  }
  @font-face {
    font-family: DIN;
    font-weight: 500;
    font-style: normal;
    font-display: fallback;
    src: url('${fontsPath}/medium/FFDINMedium.woff2') format("woff2"),
         url('${fontsPath}/medium/FFDINMedium.woff') format("woff");
  }
  @font-face {
    font-family: DIN;
    font-weight: bold;
    font-style: normal;
    font-display: fallback;
    src: url('${fontsPath}/bold/FFDINBold.woff2') format("woff2"),
         url('${fontsPath}/bold/FFDINBold.woff') format("woff");
  }
`;

export default FontsCSS;
