import { css } from '@nx-kit/styling';

export const scheduleSliderItem = css<any>`
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;

  .text {
    color: ${({ theme }) => theme.global.color.gray900};
    transition: color 300ms ${({ theme }) => theme.global.easing.snappy};

    ${({ isHovered, isActive }) =>
      (isHovered || isActive) &&
      css`
        color: ${({ theme }) => theme.global.color.primary};
      `}
  }

  ${({ isFocused, theme }) => isFocused && theme.global.focusRing};
`;

export const newsSliderItem = css<any>`
  display: block;
  overflow: hidden;
  border-radius: 8px;
  color: ${({ theme }) => theme.global.color.gray900};
  background-color: ${({ theme }) => theme.global.color.white};
  transition: box-shadow 300ms ${({ theme }) => theme.global.easing.snappy};
  box-shadow: 0 3px 12px rgba(0, 0, 0, 0.12);

  .text {
    ${({ isHovered, isActive }) =>
      (isHovered || isActive) &&
      css`
        color: ${({ theme }) => theme.global.color.primary};
      `}
  }

  ${({ isHovered, isActive }) =>
    (isHovered || isActive) &&
    css`
      box-shadow: 0 5px 18px rgba(0, 0, 0, 0.2);
    `}

  ${({ isFocused, theme }) => isFocused && theme.global.focusRing};
`;
