import { css, keyframes } from '@nx-kit/styling';

const open = keyframes`
  0% {
    opacity: 0;
    transform: translateY(-32px);
  }
  100% {
    opacity: 1;
    transform: none;
  }
`;

const defaultSkin = css<any>`
  border-bottom: 1px solid rgba(0, 0, 0, 0.09);
  padding-bottom: 15px;
  & > div[role='heading'] {
    font-size: 20px;
    line-height: 30px;
    font-weight: 700;
    margin-bottom: 0;
    overflow: hidden;

    > button {
      display: block;
      width: 100%;
      text-align: inherit;
      color: ${({ theme }) => theme.global.color.gray900};
      border: none;
      padding: 25px 50px 15px 0;
      border-radius: 16px;
      font: inherit;
      cursor: pointer;
      outline: inherit;
      position: relative;
      perspective: 50px;
      perspective-origin: calc(100% - 21px) 50%;
      ${({ isFocused, theme }) => isFocused && theme.global.focusRing};

      &[aria-disabled='true'] {
        cursor: default;
      }

      &:after {
        content: '';
        will-change: transform;
        transition: transform 300ms ${({ theme }) => theme.global.easing.default};
        position: absolute;
        transform-origin: center;
        right: 5px;
        top: calc(50% - 5px);
        width: 10px;
        height: 10px;
        border-bottom: 2px solid ${({ theme }) => theme.global.color.primary};
        border-right: 2px solid ${({ theme }) => theme.global.color.primary};
        transform: rotate3d(1, 0, 0, 0) rotate3d(0, 0, 1, 45deg);
      }
      &[aria-expanded='true']:after {
        transform: rotate3d(1, 0, 0, 180deg) rotate3d(0, 0, 1, 45deg);
      }
    }
  }
  & > div[role='region'] {
    overflow: hidden;

    > div {
      animation: ${open} 0.3s ${({ theme }) => theme.global.easing.snappy};
    }

    &[hidden] {
      display: none;
    }
  }
`;

const navigation = css<any>`
  ${defaultSkin};
  border-bottom: none;
  padding-bottom: 0;

  & > div[role='heading'] {
    font-weight: 500;
    position: absolute;
    overflow: visible;
    top: 0;
    right: 0;
    > button {
      width: 50px;
      height: 70px;
    }
  }
  & > div[role='region'] {
    padding: 0;
  }
`;

// eslint-disable-next-line import/prefer-default-export
export const accordion = {
  global: ``,
  skin: {
    default: defaultSkin,
    navigation,
  },
};
