import { css, styled } from '@nx-kit/styling';

export const Wrapper = styled.div<{
  objectFit: boolean;
  noMinHeight: boolean;
  minHeight: number;
}>`
  position: relative;
  width: 100%;
  height: 100%;
  min-height: ${(props) => (props.noMinHeight ? '0' : props.minHeight)}px;

  ${(props) =>
    props.objectFit &&
    css`
      img {
        object-fit: cover;
        width: 100%;
        height: 100%;
      }
    `}
`;
