import { createContext } from 'react';
import { DocumentRelations } from 'prismic/getDocumentRelations';

export const DocumentRelationsContext = createContext<DocumentRelations | undefined>(undefined);

interface DocumentRelationsProviderProps {
  children: any;
  relationData: any | null;
}

export const DocumentRelationsProvider = ({
  children,
  relationData,
}: DocumentRelationsProviderProps) => {
  return (
    <DocumentRelationsContext.Provider value={relationData}>
      {children}
    </DocumentRelationsContext.Provider>
  );
};
