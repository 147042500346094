import React, { ErrorInfo } from 'react';
import { ErrorPageContent } from 'components/organisms/ErrorPageContent';

class ErrorBoundary extends React.Component {
  // @ts-ignore
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError() {
    return { hasError: true };
  }

  componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    console.log('[ErrorBoundary] ', { error, errorInfo });
  }

  render() {
    // @ts-ignore
    if (this.state.hasError) {
      // @ts-ignore
      return <ErrorPageContent isRunError={this.state.hasError} />;
    }
    // @ts-ignore
    return this.props.children;
  }
}

export default ErrorBoundary;
