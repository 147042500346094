export const PRISMIC_CUSTOM_TYPES = {
  page: 'page',
  event: 'tagesplan',
  event_item: 'event_item',
  header: 'header',
  footer: 'foot',
  shared: 'shared',
  error404: '404eror',
  navigation: 'navigation',
  tagesplan_uebersicht: 'tagesplan-uebersicht',
  blog_ubersicht: 'blog_ubersicht',
  blog_item: 'blog_item',
  events_uebersicht: 'events_uebersicht',
  search_page: 'search_page',
  gebaeude: 'gebaeude',
  kurs: 'kurs',
};

export const GUIDE_BASE = 'guide';
export const courseTag = 'ballenbergkurse';
export const DEFAULT_DOMAIN: DomainType = 'ballenberg.ch';
export const COURSE_DOMAIN: DomainType = 'ballenbergkurse.ch';

export type DomainType = 'ballenberg.ch' | 'ballenbergkurse.ch';

export const DOMAINS: Array<DomainType> = [DEFAULT_DOMAIN, COURSE_DOMAIN];

export const LOCALES = {
  en: 'en',
  it: 'it',
  fr: 'fr',
  de: 'de',
  default: 'de',
};

export const LOCALES_MAP = {
  [LOCALES.en]: 'en-gb',
  [LOCALES.it]: 'it-ch',
  [LOCALES.fr]: 'fr-ch',
  [LOCALES.de]: 'de-ch',
  default: 'de-ch',
};

export const PRISMIC_API_IDS = {
  displayName: 'display_name',
  parent: 'parent',
  metaTitle: 'meta_title',
  metaDescription: 'meta_description',
  metaCanonical: 'meta_canonical',
  metaIndex: 'meta_index',
  ogTitle: 'og_title',
  ogDescription: 'og_description',
  ogImage: 'og_image',
  ogSitename: 'og_sitename',
};

export const CourseRegistrationPageUrl = '/kursanmeldung';
export const CourseRegistrationQueryKey = 'course';
