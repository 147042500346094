import { forwardRef, useEffect, useState } from 'react';
import ReactDatePicker, { registerLocale } from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import useTranslation from 'next-translate/useTranslation';
import { format, isValid } from 'date-fns';
import { de, enGB, it, fr } from 'date-fns/locale';
import { DatePickerProps, RefPropsType } from './DatePicker.types';
import { ButtonStyled, IconStyled } from './DatePicker.styles';

/*
 * DOCS: see root Readme -> Packages
 * */

export const DatePicker: React.FC<DatePickerProps> = ({
  className,
  onChange,
  currentDate,
  ...props
}) => {
  const stateDateFormat = 'yyyy-MM-dd';
  const displayDateFormat = 'dd.MM.yyyy';

  const [selectedDate, setSelectedDate] = useState(isValid(currentDate) ? currentDate : new Date());
  const { t, lang } = useTranslation('common');

  useEffect(() => {
    setSelectedDate(currentDate);
  }, [currentDate]);

  if (lang === 'de') {
    registerLocale('de', de);
  } else if (lang === 'fr') {
    registerLocale('fr', fr);
  } else if (lang === 'it') {
    registerLocale('it', it);
  } else {
    registerLocale('en', enGB);
  }

  const CustomInput = forwardRef<HTMLButtonElement, RefPropsType>(({ value, onClick }, ref) => {
    let selected;
    let today;

    if (value) {
      selected = format(new Date(value), displayDateFormat);
      today = format(new Date(), displayDateFormat);
    }

    return (
      <ButtonStyled onClick={onClick} ref={ref}>
        {selected === today ? t('today') : selected}
        <IconStyled name="CALENDAR" />
      </ButtonStyled>
    );
  });
  CustomInput.displayName = 'CustomInput';

  return (
    <div className={className}>
      <ReactDatePicker
        customInput={<CustomInput />}
        popperClassName="datepicker-popper"
        selected={selectedDate}
        locale={lang}
        dateFormat={stateDateFormat}
        onChange={(date: Date, event) => {
          setSelectedDate(date);
          if (onChange) {
            onChange(date, event);
          }
        }}
        {...props}
      />
    </div>
  );
};
