import { Text } from '@nx-kit/text';
import Link from 'components/atoms/Link/Link';
import { Heading } from 'components/atoms/Heading';
import Picture from 'components/atoms/Picture/Picture';
import { NewsSliderItemProps } from './NewsSliderItem.types';
import { ImageStyled, TextWrapper } from './NewsSliderItem.styles';

const NewsSliderItem = ({ link, image, text, subtext, className }: NewsSliderItemProps) => {
  if (!link) {
    return null;
  }

  return (
    <Link skin="newsSliderItem" url={link} className={className}>
      <ImageStyled>{image && <Picture noMinHeight image={image} objectFit />}</ImageStyled>
      <TextWrapper>
        <Text styles={{ marginBottom: 5, color: 'gray200' }}>{subtext}</Text>
        <Heading className="text" skin="500" elementType="div">
          {text}
        </Heading>
      </TextWrapper>
    </Link>
  );
};

export default NewsSliderItem;
