import { Button as NxButton, ButtonProps as NxButtonProps } from '@nx-kit/button';
import Icon from 'components/atoms/Icon/Icon';
import { IconType } from 'components/atoms/Icon/iconMap';

export type ButtonProps = NxButtonProps & {
  icon?: IconType;
  children?: string;
  onClick?: (e?: any) => void;
};

const Button = ({ icon, children, skin, ...props }: ButtonProps) => {
  return (
    // @ts-ignore
    <NxButton $content={children} {...props} skin={skin} withChildren={!!children}>
      {children && <span>{children}</span>}
      {icon && <Icon className="icon" name={icon} />}
      {skin && String(skin).startsWith('tertiary') && <Icon className="arrow" name="ARROW" />}
    </NxButton>
  );
};

export default Button;
