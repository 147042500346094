import shareUrl, { ShareChannelType } from 'helpers/shareUrl';
import isClient from 'helpers/isClient';
import Icon from 'components/atoms/Icon/Icon';
import { Channel, StyledRichText, Wrapper } from './ShareBtns.styles';

interface ShareChannel {
  channel: ShareChannelType;
  title: string;
}

interface ShareBtnsProps {
  channels: Array<ShareChannel>;
}

const ShareBtns: React.FC<ShareBtnsProps> = ({ channels }) => {
  const url = isClient() ? window?.location?.href : '';
  return (
    <Wrapper id="shareBtns">
      {channels.map((channel: ShareChannel) => {
        if (channel.channel) {
          return (
            <Channel
              key={channel.channel}
              title={channel.title}
              onClick={(): void => shareUrl(channel.channel, url)}
            >
              <Icon name={channel.channel} />
              <StyledRichText textstring={channel.title} />
            </Channel>
          );
        }
        return false;
      })}
    </Wrapper>
  );
};

export default ShareBtns;
