import { createGlobalStyle, styled } from '@nx-kit/styling';
import Icon from 'components/atoms/Icon/Icon';

export const ButtonStyled = styled.button`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 10px;
  height: 45px;
  border-radius: 6px;
  border: 1px solid ${(props) => props.theme.global.color.gray650};
  color: ${(props) => props.theme.global.color.gray900};
  background-color: ${(props) => props.theme.global.color.white};
  font-size: 14px;
  font-weight: 700;
  min-width: 136px;
  width: 100%;
`;

export const IconStyled = styled(Icon)`
  width: 28px;
  height: 28px;
  color: ${(props) => props.theme.global.color.primary};
  margin-left: 15px;
`;

export const DatePickerGlobalStyle = createGlobalStyle`
  .datepicker-popper{
    z-index:${(props) => props.theme.global.zIndex.datePicker};
  }
`;
