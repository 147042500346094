type Props = {
  className?: string;
};

const MapCursor: React.FC<Props> = ({ className }) => (
  <svg
    className={className}
    width="18"
    height="18"
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M11.5876 4.48145V16.6689M0.920898 4.48145L6.25423 1.66895L11.5876 4.48145L16.9209 1.66895V13.8564L11.5876 16.6689L6.25423 13.8564L0.920898 16.6689V4.48145ZM6.25423 1.66895V13.8564V1.66895Z"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default MapCursor;
