import { styled } from '@nx-kit/styling';

type Props = {
  className?: string;
};

const LineStyled = styled.line`
  stroke-width: 1.5;
  stroke-linecap: round;
  stroke: currentColor;
  transition: transform 400ms ${({ theme }) => theme.global.easing.snappy};
  transform-origin: 50% 50% 0;

  &:first-of-type {
    transform: rotate(45deg) translateY(4px);
  }
  &:last-of-type {
    transform: rotate(-45deg) translateY(-4px);
  }
`;

const CloseIcon: React.FC<Props> = ({ className }) => (
  <svg
    className={className}
    height={20}
    width={20}
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <LineStyled x1={1} x2={19} y1={6} y2={6} />
    <LineStyled x1={1} x2={19} y1={14} y2={14} />
  </svg>
);

export default CloseIcon;
