import { PrismicLink } from 'prismic/types';
import { DocumentRelations } from 'prismic/getDocumentRelations';
import { DOMAINS, DomainType, LOCALES_MAP } from 'prismic/config';
import getPathFromDocumentRelations from './getPathFromDocumentRelations';

const linkResolver = (
  { link_type, uid, url, lang = 'de-ch' }: PrismicLink,
  documentRelations?: DocumentRelations
): string => {
  const locale = Object.keys(LOCALES_MAP).find((key) => LOCALES_MAP[key] === lang);

  if ((link_type === 'Web' || link_type === 'Media') && url) return url;

  const path =
    lang && documentRelations?.[lang] && getPathFromDocumentRelations(uid, documentRelations[lang]);

  if (uid && DOMAINS.indexOf(uid as DomainType) < 0) {
    if (!path) {
      return `/${locale}/${uid}` as string;
    }
    return `/${locale}/${path}` as string;
  }
  return `/${locale}` as string;
};

export default linkResolver;
