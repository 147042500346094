import { media, styled } from '@nx-kit/styling';
import { BreadcrumbLinkType } from 'components/molecules/Breadcrumb/Breadcrumb.types';

const NoLink = styled.span`
  display: inline;
  font-weight: 500;
  font-size: 11px;
  line-height: 20px;
  color: ${({ theme }) => theme.global.color.gray900};

  ${media('md')} {
    font-size: 14px;
    line-height: 20px;
  }
`;

const StyledLink = styled.a`
  font-weight: 400;
  text-decoration: none;
  font-size: 11px;
  line-height: 20px;
  color: ${({ theme }) => theme.global.color.primary};

  ${media('md')} {
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
  }
`;

const BreadcrumbLink: React.FC<BreadcrumbLinkType> = ({ url, label }) => {
  if (url === null) {
    return <NoLink>{label}</NoLink>;
  }
  return <StyledLink href={url}>{label}</StyledLink>;
};

export default BreadcrumbLink;
