import { media, styled } from '@nx-kit/styling';

export const Wrapper = styled.div`
  margin-bottom: 10px;
  ${media('md')} {
    margin-bottom: 16px;
  }
`;

export const Arrow = styled.span`
  color: ${({ theme }) => theme.global.color.gray300};
  margin: 0 5px;
  font-weight: 500;
`;
