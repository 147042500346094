type Props = {
  className?: string;
};

const CenterMapIcon: React.FC<Props> = ({ className }) => (
  <svg
    className={className}
    width="1em"
    height="1em"
    viewBox="0 0 27 27"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="13.4238" cy="13.2602" r="10" stroke="currentColor" strokeWidth="2" />
    <circle cx="13.4258" cy="13.2602" r="3.8535" stroke="currentColor" strokeWidth="2" />
    <line
      x1="13.4238"
      y1="1.26019"
      x2="13.4238"
      y2="5.26019"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
    />
    <line
      x1="13.4238"
      y1="21.2602"
      x2="13.4238"
      y2="25.2602"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
    />
    <line
      x1="1.42383"
      y1="13.0504"
      x2="5.42383"
      y2="13.0504"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
    />
    <line
      x1="21.4238"
      y1="13.0504"
      x2="25.4238"
      y2="13.0504"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
    />
  </svg>
);

export default CenterMapIcon;
