import { css, styled } from '@nx-kit/styling';

export const Wrapper = styled.div<{ background?: string }>`
  color: ${({ theme }) => theme.global.color.gray900};
  z-index: ${({ theme }) => theme.global.zIndex.two};

  ${({ background }) =>
    background !== undefined &&
    css`
      background-color: ${background};
    `};

  h1 {
    ${({ theme }) => theme.component.heading[800]}
  }
  h2 {
    ${({ theme }) => theme.component.heading[700]}
  }
  h3 {
    ${({ theme }) => theme.component.heading[600]}
  }
  h4 {
    ${({ theme }) => theme.component.heading[500]}
  }
  h5 {
    ${({ theme }) => theme.component.heading[400]}
  }
  h6 {
    ${({ theme }) => theme.component.heading[400]}
  }

  p + p {
    margin-top: 10px;
  }

  p.block-img {
    margin: 50px 0;

    &:last-child {
      margin-bottom: 0;
    }
  }

  p + h2 {
    margin-top: 20px;
  }

  p + h3 {
    margin-top: 13px;
  }

  p + h4 {
    margin-top: 10px;
  }

  strong {
    font-weight: bold;
  }
  em {
    font-style: italic;
  }
  ul li:before {
    border-radius: 0;
  }
  ol {
    padding-left: 1.5em;
  }
  a {
    text-decoration: none;
    color: ${({ theme }) => theme.global.color.primary};
    &:focus {
      outline: none;
    }
    &:hover {
      text-decoration: underline;
    }
  }
`;
