import { styled } from '@nx-kit/styling';

export const ImageStyled = styled.div`
  min-height: 184px;
  height: 184px;
  width: 100%;
`;

export const TextWrapper = styled.div`
  padding: 20px 30px 30px;
`;
