import { styled, css } from '@nx-kit/styling';
import { CourseStatusColors } from 'pages/api/courseApi.types';

export const CourseStateLight = styled.div<{ color: string }>`
  min-width: 14px;
  width: 14px;
  height: 14px;
  border-radius: 7px;
  ${({ color, theme }) => {
    if (color === CourseStatusColors.green) {
      return css`
        background-color: ${theme.global.color.statusGreen};
      `;
    }
    if (color === CourseStatusColors.orange) {
      return css`
        background-color: ${theme.global.color.statusOrange}; ;
      `;
    }
    return css`
      background-color: ${theme.global.color.statusRed}; ;
    `;
  }}
`;
