import { createContext, ReactNode, useRef, useState } from 'react';

export const HeaderContext = createContext({
  open: -1,
  setOpen: (_index: number) => {},
});

export const HeaderProvider = ({ children }: { children: ReactNode }) => {
  const timeoutRef = useRef<any>();
  const [open, setStateOpen] = useState(-1);

  const setOpen = (index: number) => {
    clearTimeout(timeoutRef.current);

    timeoutRef.current = setTimeout(
      () => {
        setStateOpen(index);
      },
      index < 0 ? 100 : 0
    );
  };

  return <HeaderContext.Provider value={{ open, setOpen }}>{children}</HeaderContext.Provider>;
};
