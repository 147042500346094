export enum AnlassStatus {
  open = 'Offen',
  cancel = 'Abgesagt',
  takesPlace = 'Findet statt',
}

export type AnlassArt = null | string | 'Kurs';
export type AnlassTyp = null | '-' | string | 'Formen und Gestalten';
export type AnlassTypId = null | number;
export type HtmlString = string | null;
export type NullableString = string | null;

export interface CourseCategoriesListItem {
  value: string;
  apiLabel: string;
}

export const CourseCategoriesList: CourseCategoriesListItem[] = [
  { value: '0', apiLabel: 'Backen' },
  { value: '1', apiLabel: 'Bau' },
  { value: '2', apiLabel: 'Besenbinden' },
  { value: '3', apiLabel: 'Bildnerisches Gestalten' },
  { value: '4', apiLabel: 'Bleiverglasen' },
  { value: '5', apiLabel: 'Bogenbauen' },
  { value: '6', apiLabel: 'Buchbinden' },
  { value: '7', apiLabel: 'Buchmalen' },
  { value: '8', apiLabel: 'Drechseln' },
  { value: '9', apiLabel: 'Drucken' },
  { value: '10', apiLabel: 'Einzelkurs' },
  { value: '11', apiLabel: 'Fachkurs' },
  { value: '12', apiLabel: 'Falten' },
  { value: '13', apiLabel: 'Färben' },
  { value: '14', apiLabel: 'Filochieren' },
  { value: '15', apiLabel: 'Filzen' },
  { value: '16', apiLabel: 'Flechten' },
  { value: '17', apiLabel: 'Fotografie' },
  { value: '18', apiLabel: 'Fotografieren' },
  { value: '19', apiLabel: 'Garnmalen' },
  { value: '20', apiLabel: 'Gartenarbeiten' },
  { value: '21', apiLabel: 'Glasen' },
  { value: '22', apiLabel: 'Glasverarbeitung' },
  { value: '23', apiLabel: 'Gruppenangebot' },
  { value: '24', apiLabel: 'Heilkunde' },
  { value: '25', apiLabel: 'Holzverarbeitung' },
  { value: '26', apiLabel: 'Holzverarbeitung' },
  { value: '27', apiLabel: 'Hutmachen' },
  { value: '28', apiLabel: 'Keramikverarbeitung' },
  { value: '29', apiLabel: 'Kinderkurs' },
  { value: '30', apiLabel: 'Klöppeln' },
  { value: '31', apiLabel: 'Knopfmachen' },
  { value: '32', apiLabel: 'Knüpfen' },
  { value: '33', apiLabel: 'Köhlern' },
  { value: '34', apiLabel: 'Kosmetik' },
  { value: '35', apiLabel: 'Kürschnern' },
  { value: '36', apiLabel: 'Lackieren' },
  { value: '37', apiLabel: 'Lebensmittelherstellung' },
  { value: '38', apiLabel: 'Lederverarbeitung' },
  { value: '39', apiLabel: 'Mähen' },
  { value: '40', apiLabel: 'Malen' },
  { value: '41', apiLabel: 'Metallverarbeitung' },
  { value: '42', apiLabel: 'Modellieren' },
  { value: '43', apiLabel: 'Nähen' },
  { value: '44', apiLabel: 'Papierverarbeitung' },
  { value: '45', apiLabel: 'Pendeln' },
  { value: '46', apiLabel: 'Pflästern' },
  { value: '47', apiLabel: 'Pigmente verarbeiten' },
  { value: '48', apiLabel: 'Polstern' },
  { value: '49', apiLabel: 'Restaurieren' },
  { value: '50', apiLabel: 'Sattlern' },
  { value: '51', apiLabel: 'Scherenschnitt' },
  { value: '52', apiLabel: 'Schmieden' },
  { value: '53', apiLabel: 'Schnitzen' },
  { value: '54', apiLabel: 'Schreiben' },
  { value: '55', apiLabel: 'Schreinern' },
  { value: '56', apiLabel: 'Schuhmachen' },
  { value: '57', apiLabel: 'Schweissen' },
  { value: '58', apiLabel: 'Seifen sieden' },
  { value: '59', apiLabel: 'Seilen' },
  { value: '60', apiLabel: 'Spezialkurse' },
  { value: '61', apiLabel: 'Spinnen' },
  { value: '62', apiLabel: 'Steinverarbeitung' },
  { value: '63', apiLabel: 'Sticken' },
  { value: '64', apiLabel: 'Stricken' },
  { value: '65', apiLabel: 'Stuckprofilziehen' },
  { value: '66', apiLabel: 'Textilverarbeitung' },
  { value: '67', apiLabel: 'Töpfern' },
  { value: '68', apiLabel: 'Verarbeitung von Naturmaterialien' },
  { value: '69', apiLabel: 'Weben' },
  { value: '70', apiLabel: 'Ziselieren' },
];

export interface Person {
  AdresseId: number;
  AdresseNr: NullableString;
  Titel: NullableString;
  Vorname: NullableString;
  Nachname: NullableString;
  Email: NullableString;
  Url: NullableString;
}

export interface CourseItem {
  AnlassId: number;
  AnlassNr: string;
  AnlassStatusId: number;
  AnlassStatus: AnlassStatus;
  AnlassArtId: number;
  AnlassArt: AnlassArt;
  AnlassTyp1Id: AnlassTypId;
  AnlassTyp1: AnlassTyp;
  AnlassTyp2Id: AnlassTypId;
  AnlassTyp2: AnlassTyp;
  AnlassTyp3Id: AnlassTypId;
  AnlassTyp3: AnlassTyp;
  AnlassTyp4Id: AnlassTypId;
  AnlassTyp4: AnlassTyp;
  AnlassTyp5Id: AnlassTypId;
  AnlassTyp5: AnlassTyp;
  AnlassFormId: AnlassTypId;
  AnlassForm: null;
  PrintMedien: boolean;
  Web: boolean;
  WebBuchen: boolean;
  Beginn: string;
  Ende: string;
  AnmeldefristTage: number;
  Daten: string;
  Zeiten: string;
  DauerTage: number;
  DauerText: NullableString;
  Dauer: number;
  UnterrichtsPraesenzZeit: number;
  Lernzeit: number;
  Credits: number;
  Preis: number;
  Anzahlung: number;
  GeschaeftArtId: null;
  GeschaeftArt: null;
  TnMinimal: number;
  TnMaximal: number;
  TnBelegt: number;
  Sachbearbeiter: Person;
  Verantwortlich: Person;
  Leiter: Person;
  Lokal: NullableString;
  Strasse: NullableString;
  Plz: NullableString;
  Ort: NullableString;
  Text1: NullableString;
  Text2: NullableString;
  Text3: NullableString;
  Text4: NullableString;
  Text5: NullableString;
  BildLink1: NullableString;
  BildLink2: NullableString;
  BildLink3: NullableString;
  ErfasstDat: NullableString;
  MutationDat: NullableString;
  Titel: NullableString;
  TitelKurz: NullableString;
  Beschreibung: HtmlString;
  BeschreibungDetail: NullableString;
  Inhalt: HtmlString;
  Hinweis: HtmlString;
  Voraussetzung: NullableString;
  Ziel: NullableString;
  Kompetenz: NullableString;
  Kompetenznachweis: NullableString;
  Anerkennung: NullableString;
  Laufzeit: NullableString;
  Gueltigkeit: NullableString;
  Anmeldefrist: NullableString;
  DetailHtm: NullableString;
}

export enum CourseStatusColors {
  green = 'green',
  orange = 'orange',
  red = 'red',
}

export interface CourseStatus {
  label: string;
  color: CourseStatusColors;
}

export interface ResponseCourseItem {
  image: any; // PrismicResponsiveImage
  title: string;
  description: string;
  date: string;
  time: string;
  place: string;
  person: string;
  price_format: string;
  info: HtmlString;
  status_color: CourseStatusColors;
  status_label: string;
  _price: number | undefined;
  _anlassNr: string | undefined | null;
  _anlassId: number;
  _themen: string[];
  _meta: {
    id: string | null | undefined;
    uid?: string | null;
    type?: string;
    lang: string;
    __typename?: string;
  };
  _dateToSort: Date | undefined;
}
