/* eslint-disable function-paren-newline */
import { css, LiteralOrBreakpoints, styled, media } from '@nx-kit/styling';

interface ColumnProps {
  span: LiteralOrBreakpoints<number>;
  start?: LiteralOrBreakpoints<number>;
  row?: LiteralOrBreakpoints<number>;
  className?: string;
  children?: any;
}

const StyledColumns = styled.div<{
  span: LiteralOrBreakpoints<number>;
  start?: LiteralOrBreakpoints<number>;
  row?: LiteralOrBreakpoints<number>;
}>`
  grid-column-start: auto;
  grid-column-end: span 12;
  align-self: stretch;
  justify-self: stretch;
  ${({ row }) => {
    const styles: Array<any> = [];
    if (typeof row === 'number') {
      styles.push(css`
        grid-row: ${row};
      `);
    } else if (typeof row === 'object') {
      Object.entries(row).forEach(([bp, value]) =>
        styles.push(css`
          ${media(bp)} {
            grid-row: ${value};
          }
        `)
      );
    }
    return styles;
  }}
  ${({ start }) => {
    const styles: Array<any> = [];
    if (typeof start === 'number') {
      styles.push(css`
        grid-column-start: col ${start};
      `);
    } else if (typeof start === 'object') {
      Object.entries(start).forEach(([bp, value]) =>
        styles.push(css`
          ${media(bp)} {
            grid-column-start: col ${value};
          }
        `)
      );
    }
    return styles;
  }}
  ${({ span }) => {
    const styles: Array<any> = [];
    if (typeof span === 'number') {
      styles.push(css`
        grid-column-end: span ${span};
      `);
    } else if (typeof span === 'object') {
      Object.entries(span).forEach(([bp, value]) =>
        styles.push(css`
          ${media(bp)} {
            grid-column-end: span ${value};
          }
        `)
      );
    }
    return styles;
  }}
`;

const Column = ({ start, span, row, children, className }: ColumnProps) => (
  <StyledColumns start={start} span={span} row={row} className={className}>
    {children}
  </StyledColumns>
);

export default Column;
