import useTranslation from 'next-translate/useTranslation';
import { styled } from '@nx-kit/styling';
import { RichText } from 'components/atoms/RichText';
import ShareBtns from 'components/molecules/ShareBtns/ShareBtns';

const Wrapper = styled.div`
  margin-top: 40px;
  display: flex;
  align-items: center;
`;

const StyledRichText = styled(RichText)`
  font-size: ${({ theme }) => theme.global.fontSize.small}px;
  white-space: nowrap;
  margin-right: 12px;
`;

const TextAndIcons = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

export const HeroShareButtons: React.FC<{ className?: string }> = ({ className }) => {
  const { t } = useTranslation('common');
  return (
    <Wrapper className={className}>
      <TextAndIcons>
        <StyledRichText textstring={t('sharePage')} />
        <ShareBtns
          channels={[
            { channel: 'FACEBOOK', title: 'Facebook' },
            { channel: 'TWITTER', title: 'X' },
            { channel: 'LINKEDIN', title: 'LinkedIn' },
            { channel: 'MAIL', title: 'E-Mail' },
          ]}
        />
      </TextAndIcons>
    </Wrapper>
  );
};
