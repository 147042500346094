type Props = {
  className?: string;
};

const CalendarIcon: React.FC<Props> = ({ className }) => (
  <svg
    className={className}
    width="1em"
    height="1em"
    viewBox="0 0 25 25"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M18.5459 6.72925H7.2959C6.26036 6.72925 5.4209 7.52519 5.4209 8.50703V19.1737C5.4209 20.1555 6.26036 20.9515 7.2959 20.9515H18.5459C19.5814 20.9515 20.4209 20.1555 20.4209 19.1737V8.50703C20.4209 7.52519 19.5814 6.72925 18.5459 6.72925Z"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M16.6709 4.9519V8.50746M9.1709 4.9519V8.50746M5.4209 12.063H20.4209"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default CalendarIcon;
