import { media, styled } from '@nx-kit/styling';
import NewsSliderItem from 'components/molecules/NewsSliderItem/NewsSliderItem';
import { DatePicker } from 'components/molecules/DatePicker';
import Column from 'components/layout/Grid/Column';

export const ColumnStyled = styled(Column)`
  position: relative;
`;

export const RowFilterStyled = styled.div`
  display: flex;
  margin: 50px 0;
  flex-direction: column;
  ${media('lg')} {
    flex-direction: row;
    align-items: center;
  }
`;

export const FilterGroupStyled = styled.div`
  display: flex;
  flex-direction: column;
`;

export const FilterGroupWithSpaceStyled = styled(FilterGroupStyled)`
  margin: 0 0 30px 0;
  ${media('lg')} {
    margin: 0 120px 0 0;
  }
`;

export const ResultsCounterStyled = styled.div`
  color: ${({ theme }) => theme.global.color.gray180};
`;

export const RowFilterHeadlineStyled = styled.div`
  font-weight: 700;
`;

export const RowGridStyled = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 16px;
`;

export const RowLoadingStyled = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 80px;
`;

export const RowPaginationStyled = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 10px;
`;

export const DatePickersGroupStyled = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 16px;
`;

export const DatePickerStyled = styled(DatePicker)`
  margin: 0;
`;

export const NewsSliderItemStyled = styled(NewsSliderItem)`
  flex: 1 1 auto;

  ${media('sm')} {
    flex: 1 1 50%;
    max-width: calc(50% - 16px);
  }

  ${media('md')} {
    flex: 1 1 30%;
    max-width: calc(33% - 32px);
  }
`;
