import { css } from '@nx-kit/styling';

export const primary = css<any>`
  position: relative;
  display: inline-flex;
  justify-content: flex-start;
  align-items: center;
  font-family: ${({ theme }) => theme.global.font.brandRegular};
  font-weight: 700;
  font-size: 13px;
  line-height: 20px;
  text-transform: uppercase;
  padding: 13px 15px;
  border-radius: 6px;
  color: ${({ theme }) => theme.global.color.white};
  background-color: ${({ theme }) => theme.global.color.primary};
  text-decoration: none;

  && {
    outline: 0 solid ${({ theme }) => theme.global.color.primary};
    transition: outline-width 100ms ${({ theme }) => theme.global.easing.snappy};
  }

  > .icon {
    width: 23px;
    height: 23px;
  }

  ${({ withChildren }) =>
    withChildren &&
    css`
      > .icon {
        margin-inline-start: 12px;
      }
    `}

  ${({ isActive, isHovered }) =>
    (isActive || isHovered) &&
    css`
      &&& {
        outline-width: 2px;
      }
    `}
  ${({ isFocused, theme }) => isFocused && theme.global.focusRing};
`;

export const secondary = css<any>`
  ${primary}
  && {
    outline-width: 1px;
  }
  background-color: transparent;
  color: ${({ theme }) => theme.global.color.primary};
`;

export const tertiaryBig = css<any>`
  display: inline-flex;
  justify-content: flex-start;
  align-items: center;
  font-family: ${({ theme }) => theme.global.font.brandRegular};
  font-weight: 400;
  font-size: 20px;
  line-height: 30px;
  letter-spacing: 0.01em;
  color: ${({ theme }) => theme.global.color.primary};

  > .icon {
    margin-inline-start: 12px;
    width: 23px;
    height: 23px;
  }

  > .arrow {
    width: 30px;
    margin-inline-start: 10px;
    transition: transform 300ms ${({ theme }) => theme.global.easing.snappy};
    color: currentColor;
  }
  ${({ isActive, isHovered }) =>
    (isActive || isHovered) &&
    css`
      &&& > .arrow {
        transform: translateX(10px);
      }
    `}
  ${({ isFocused, theme }) => isFocused && theme.global.focusRing};
`;

export const tertiarySmall = css<any>`
  ${tertiaryBig};
  font-size: 16px;
  line-height: 26px;
`;

export const minimal = css<any>`
  display: inline-flex;
  justify-content: flex-start;
  align-items: center;
  font-family: ${({ theme }) => theme.global.font.brandRegular};
  font-weight: 400;
  font-size: 16px;
  line-height: 26px;
  letter-spacing: 0.01em;
  color: ${({ theme }) => theme.global.color.primary};
`;

export const teaser = css<any>`
  ${({ isActive, isHovered }) =>
    (isActive || isHovered) &&
    css`
      .image {
        transform: scale(1.05);
        &:after {
          opacity: 1;
        }
      }

      .arrow {
        transform: translateX(10px);
      }
    `}
  ${({ isFocused, theme }) => isFocused && theme.global.focusRing};
`;
