type Props = {
  className?: string;
};

const QrCode: React.FC<Props> = ({ className }) => (
  <svg
    className={className}
    width="39"
    height="39"
    viewBox="0 0 39 39"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M7.96973 19.4434H13.9697M25.9697 19.4434V25.4434M19.9697 31.4434H25.9697M19.9697 19.4654L19.9897 19.4434M31.9697 19.4654L31.9897 19.4434M19.9697 25.4654L19.9897 25.4434M31.9697 25.4654L31.9897 25.4434M31.9697 31.4654L31.9897 31.4434M19.9697 13.4654L19.9897 13.4434M19.9697 7.46536L19.9897 7.44336M31.9697 1.44336H37.9697V7.44336M31.9697 37.4434H37.9697V31.4434M7.96973 1.44336H1.96973V7.44336M7.96973 37.4434H1.96973V31.4434M13.9697 8.64336V12.2434C13.9697 12.5616 13.8433 12.8668 13.6183 13.0919C13.3932 13.3169 13.088 13.4434 12.7697 13.4434H9.16973C8.85147 13.4434 8.54624 13.3169 8.3212 13.0919C8.09615 12.8668 7.96973 12.5616 7.96973 12.2434V8.64336C7.96973 8.3251 8.09615 8.01987 8.3212 7.79483C8.54624 7.56979 8.85147 7.44336 9.16973 7.44336H12.7697C13.088 7.44336 13.3932 7.56979 13.6183 7.79483C13.8433 8.01987 13.9697 8.3251 13.9697 8.64336ZM13.9697 26.6434V30.2434C13.9697 30.5616 13.8433 30.8668 13.6183 31.0919C13.3932 31.3169 13.088 31.4434 12.7697 31.4434H9.16973C8.85147 31.4434 8.54624 31.3169 8.3212 31.0919C8.09615 30.8668 7.96973 30.5616 7.96973 30.2434V26.6434C7.96973 26.3251 8.09615 26.0199 8.3212 25.7948C8.54624 25.5698 8.85147 25.4434 9.16973 25.4434H12.7697C13.088 25.4434 13.3932 25.5698 13.6183 25.7948C13.8433 26.0199 13.9697 26.3251 13.9697 26.6434ZM31.9697 8.64336V12.2434C31.9697 12.5616 31.8433 12.8668 31.6183 13.0919C31.3932 13.3169 31.088 13.4434 30.7697 13.4434H27.1697C26.8515 13.4434 26.5462 13.3169 26.3212 13.0919C26.0962 12.8668 25.9697 12.5616 25.9697 12.2434V8.64336C25.9697 8.3251 26.0962 8.01987 26.3212 7.79483C26.5462 7.56979 26.8515 7.44336 27.1697 7.44336H30.7697C31.088 7.44336 31.3932 7.56979 31.6183 7.79483C31.8433 8.01987 31.9697 8.3251 31.9697 8.64336Z"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default QrCode;
