interface Label {
  [key: string]: {
    [key: string]: {
      [key: string]: string;
    };
  };
}

export const labels: Label = {
  de: {
    errorPage: {
      title: '404 – haben Sie sich in der Tür geirrt?',
      text: 'Diese Seite wurde gelöscht, verschoben oder hat vielleicht auch nie existiert. Da es im Freilichtmuseum Ballenberg aber sooooo viel zu sehen gibt, ist das gar nicht immer leicht zu sagen.',
      buttonBack: 'zur Startseite',
    },
    errorPageCourse: {
      title: '404 – auf dieser Seite ist der Unterricht leider ausgefallen.',
      text: 'Diese Seite wurde gelöscht, verschoben oder hat vielleicht auch nie existiert. Da es im Kurszentrum Ballenberg aber sooooo viel Neues zu lernen gibt, kann das schon einmal vorkommen.',
      buttonBack: 'zur Startseite',
    },
    runError: {
      title: 'Hoppla!',
      text: 'Da ist etwas schief gelaufen!',
      buttonBack: 'zur Startseite',
    },
  },
  en: {
    errorPage: {
      title: '404 – Oops! Wrong door',
      text: 'This page seems to have been deleted or moved. Or maybe it never existed. There’s sooooo much to see at the Swiss Open-Air Museum, it’s not always easy to say.',
      buttonBack: 'Go to homepage',
    },
    errorPageCourse: {
      title: '404 – Oops! Wrong door',
      text: 'This page seems to have been deleted or moved. Or maybe it never existed. There’s sooooo much to see at the Swiss Open-Air Museum, it’s not always easy to say.',
      buttonBack: 'Go to homepage',
    },
    runError: {
      title: 'Oops!',
      text: 'Something went wrong',
      buttonBack: 'Go to homepage',
    },
  },
  it: {
    errorPage: {
      title: '404 – avete sbagliato strada?',
      text: 'Questa pagina è stata cancellata, spostata oppure potrebbe non essere mai esistita. Non succede spesso: dopotutto il Museo all’aperto Ballenberg offre un’infinità di attrazioni.',
      buttonBack: 'Alla pagina iniziale',
    },
    errorPageCourse: {
      title: '404 – avete sbagliato strada?',
      text: 'Questa pagina è stata cancellata, spostata oppure potrebbe non essere mai esistita. Non succede spesso: dopotutto il Museo all’aperto Ballenberg offre un’infinità di attrazioni.',
      buttonBack: 'Alla pagina iniziale',
    },
    runError: {
      title: 'Ops',
      text: 'Qualcosa è andato storto',
      buttonBack: 'Alla pagina iniziale',
    },
  },
  fr: {
    errorPage: {
      title: '404 – Mauvaise porte?',
      text: 'Cette page a été supprimée, déplacée ou n’a peut-être jamais existé. Il y a tant de choses à voir au Musée en plein air Ballenberg que c’est difficile à dire.',
      buttonBack: 'Accéder à la page d’accueil',
    },
    errorPageCourse: {
      title: '404 – Mauvaise porte?',
      text: 'Cette page a été supprimée, déplacée ou n’a peut-être jamais existé. Il y a tant de choses à voir au Musée en plein air Ballenberg que c’est difficile à dire.',
      buttonBack: 'Accéder à la page d’accueil',
    },
    runError: {
      title: 'Hop là !',
      text: 'Quelque chose a mal tourné',
      buttonBack: 'Accéder à la page d’accueil',
    },
  },
};

export const errorT = (lang: string) => {
  const _lang = lang === 'default' ? 'de' : lang;
  return labels[_lang];
};
