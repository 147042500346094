/* eslint-disable @next/next/no-img-element */
import { useContext } from 'react';
import useTranslation from 'next-translate/useTranslation';
import { DomainContext } from 'contexts/domain/DomainContext';

type LogoProps = {
  className?: string;
};

const Logo = ({ className }: LogoProps) => {
  const {
    computed: { isCourse },
  } = useContext(DomainContext);

  const { lang } = useTranslation('common');

  const imageSrc = isCourse
    ? 'ballenbergkurse-DE.svg'
    : `ballenberg-${lang === 'default' ? 'de' : lang}.svg`;

  return <img src={`/logo/${imageSrc}`} alt="home" className={className} />;
};

export default Logo;
