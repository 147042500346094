import find from 'lodash/find';
import { DocumentEntry } from 'prismic/getDocumentRelations';

const findDocumentRelations = (uid: string, documentRelations: any) => {
  return find(documentRelations, (item) => uid === item.uid);
};

// this function returns all uids of parent elements regardless of depth
// but in reverse order, so we need to revert it in getPathFromDocumentRelations()
export const getPathRecursively = (tree: DocumentEntry, path: string[] = []) => {
  if (tree?.uid) {
    path.push(tree.uid);
    if (tree.parent !== null) {
      path.push(...getPathRecursively(tree.parent));
    }
  }
  return path;
};

const getPathFromDocumentRelations = (
  uid: string | null | undefined,
  documentRelations: any
): string | false => {
  if (uid) {
    return getPathRecursively(findDocumentRelations(uid, documentRelations)).reverse().join('/');
  }
  return false;
};

export default getPathFromDocumentRelations;
