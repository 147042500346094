import { defaultTheme } from 'themes/default';

const getSpacing = (breakpoint: 'xl' | 'xs', sliceName: string, nextSlice?: string): number => {
  const { spacing } = defaultTheme;
  let margin = 0;

  if (nextSlice) {
    if (spacing[breakpoint].cmsElements[sliceName]) {
      margin =
        spacing[breakpoint].cmsElements[sliceName][nextSlice] ??
        spacing[breakpoint].cmsElements[sliceName].default;
    } else {
      margin = spacing[breakpoint].default;
    }
  }

  return margin;
};

export default getSpacing;
