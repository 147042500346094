/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect, useRef } from 'react';
import { useBreakpointsSorted } from '@nx-kit/styling';
import { BreakpointProvider } from './BreakpointContext';

const BreakpointProviderWrapper = ({ children }: { children?: React.ReactNode }) => {
  const mqls = useRef<any>([]);
  const breakpointsSorted: Array<any> = useBreakpointsSorted();
  const [breakpoint, setBreakpoint] = useState('xs');

  useEffect(() => {
    const register = (size: string) => (e: MediaQueryListEvent | MediaQueryList) => {
      if (e.matches) {
        setBreakpoint(size);
      }
    };

    breakpointsSorted.forEach((bp) => {
      const mql = window.matchMedia(
        `(min-width: ${bp.min}px)${bp.max ? ` and (max-width: ${bp.max}px)` : ''}`
      );
      const listener = register(bp.breakpoint);
      mql.addListener(listener);
      listener(mql);
      mqls.current = [...mqls.current, { mql, listener }];
    });
    return () => {
      mqls.current.forEach((m: any) => m.mql.removeListener(m.listener));
    };
  }, []);
  return <BreakpointProvider value={breakpoint}>{children}</BreakpointProvider>;
};

export default BreakpointProviderWrapper;
