import { css } from '@nx-kit/styling';
import { primary, secondary, tertiaryBig, tertiarySmall, teaser, minimal } from './cta';

// eslint-disable-next-line import/prefer-default-export
export const button = {
  global: css<any>``,
  skin: {
    primary,
    secondary,
    tertiaryBig,
    tertiarySmall,
    teaser,
    minimal,
  },
};
