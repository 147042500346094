type Props = {
  className?: string;
};

const TwitterIcon: React.FC<Props> = ({ className }) => (
  <svg
    className={className}
    version="1.2"
    baseProfile="tiny"
    xmlns="http://www.w3.org/2000/svg"
    width="25px"
    height="23px"
    viewBox="0 0 25 23"
  >
    <path
      fill="currentColor"
      d="M 19.667969 0 L 23.5 0 L 15.082031 9.761719 L 24.917969 23 L 17.199219 23 L 11.160156 14.953125 L 4.242188 23 L 0.410156 23 L 9.324219 12.5625 L -0.0898438 0 L 7.816406 0 L 13.273438 7.351562 Z M 18.324219 20.707031 L 20.449219 20.707031 L 6.699219 2.207031 L 4.417969 2.207031 Z M 18.324219 20.707031 "
    />
  </svg>
);

export default TwitterIcon;
