
// @ts-ignore
    import __i18nConfig from '@next-translate-root/i18n'
// @ts-ignore
    import __appWithI18n from 'next-translate/appWithI18n'
// @ts-ignore
    
import { SSRProvider } from '@nx-kit/ssr';
import { ApolloProvider } from '@apollo/client';
import { PrismicPreview } from '@prismicio/next';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import StylesWrapper from 'styles/StylesWrapper';
import ContextsWrapper from 'contexts/ContextsWrapper';
import { graphQlClient } from 'prismic/helpers/fetchContent';
import { ToastNotifications } from 'components/atoms/ToastNotifications';
import ErrorBoundary from 'components/organisms/ErrorBoundary/ErrorBoundary';
import {
  GoogleTagManagerNoScript,
  GoogleTagManagerScript,
} from 'components/atoms/GoogleTagManager';

function MyApp({ Component, pageProps }: any) {
  const queryClient = new QueryClient();

  return (
    <PrismicPreview repositoryName={process.env.NEXT_PUBLIC_PRISMIC_REPO_NAME || ''}>
      <SSRProvider>
        <ErrorBoundary>
          <QueryClientProvider client={queryClient}>
            <ApolloProvider client={graphQlClient}>
              <ContextsWrapper
                domain={pageProps.domain}
                documentRelations={pageProps.documentRelations}
                sharedData={pageProps.sharedData}
                guideData={pageProps.guideData}
              >
                <StylesWrapper>
                  <GoogleTagManagerScript />
                  <GoogleTagManagerNoScript />
                  <Component {...pageProps} />
                </StylesWrapper>
                <ToastNotifications />
              </ContextsWrapper>
            </ApolloProvider>
          </QueryClientProvider>
        </ErrorBoundary>
      </SSRProvider>
    </PrismicPreview>
  );
}

const __Page_Next_Translate__ = MyApp;


// @ts-ignore
    export default __appWithI18n(__Page_Next_Translate__, {
// @ts-ignore
      ...__i18nConfig,
// @ts-ignore
      isLoader: true,
// @ts-ignore
      skipInitialProps: true,
// @ts-ignore
      loadLocaleFrom: (l, n) => import(`@next-translate-root/locales/${l}/${n}`).then(m => m.default),
// @ts-ignore
    });
// @ts-ignore
  