import { styled } from '@nx-kit/styling';
import useTranslation from 'next-translate/useTranslation';
import { LOCALES_MAP } from 'prismic/config';
import Icon from 'components/atoms/Icon/Icon';

export interface DateTimeProps {
  date: Date;
  timeslot: string;
}

const DateTimeContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 10px;
  width: 100%;
  div:first-child {
    margin-right: 35px;
  }
`;

const Item = styled.div`
  align-items: center;
  display: flex;
`;

const StyledIcon = styled(Icon)`
  color: ${({ theme }) => theme.global.color.primary};
  margin-right: 10px;
`;

const dateOptions: Intl.DateTimeFormatOptions = { year: 'numeric', month: 'long', day: 'numeric' };

export const DateTimeInfo: React.FC<DateTimeProps> = ({ date, timeslot }) => {
  const { lang } = useTranslation('common');
  const locale = Object.keys(LOCALES_MAP).find((key) => LOCALES_MAP[key] === lang);
  return (
    <DateTimeContainer>
      <Item>
        <StyledIcon name="CALENDAR" />
        <span>{date.toLocaleDateString(locale, dateOptions)}</span>
      </Item>
      <Item>
        <StyledIcon name="CLOCK" />
        <span>{timeslot.replace(/\:/g, '.')}</span>
      </Item>
    </DateTimeContainer>
  );
};
