import { defaultTheme } from './default';
import { Theme } from './themeTypes';

const ballenbergkurseTheme: Theme = {
  ...defaultTheme,
  global: {
    ...defaultTheme.global,
    color: {
      ...defaultTheme.global.color,
      primary: '#34A4D4',
    },
  },
};

export default ballenbergkurseTheme;
