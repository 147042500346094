import Script from 'next/script';
import { useContext } from 'react';
import { DomainContext } from 'contexts/domain/DomainContext';

export const GoogleTagManagerScript: React.FC = () => {
  const {
    computed: { isCourse },
  } = useContext(DomainContext);

  const id = isCourse ? process.env.NEXT_PUBLIC_GTM_KURSE : process.env.NEXT_PUBLIC_GTM;

  if (!id) {
    return null;
  }

  return (
    <Script
      id="google-tag-manager"
      dangerouslySetInnerHTML={{
        __html: `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
})(window,document,'script','dataLayer','${id}');`,
      }}
    ></Script>
  );
};

export const GoogleTagManagerNoScript: React.FC = () => {
  const {
    computed: { isCourse },
  } = useContext(DomainContext);

  const id = isCourse ? process.env.NEXT_PUBLIC_GTM_KURSE : process.env.NEXT_PUBLIC_GTM;

  if (!id) {
    return null;
  }

  return (
    <noscript>
      <iframe
        src={`https://www.googletagmanager.com/ns.html?id=${id}`}
        height="0"
        width="0"
        style={{ display: 'none', visibility: 'hidden' }}
      />
    </noscript>
  );
};
