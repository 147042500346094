type Props = {
  className?: string;
};

const DownloadIcon: React.FC<Props> = ({ className }) => (
  <svg
    className={className}
    width="18"
    height="20"
    viewBox="0 0 18 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M1.0791 14.5V16.5C1.0791 17.0304 1.28982 17.5391 1.66489 17.9142C2.03996 18.2893 2.54867 18.5 3.0791 18.5H15.0791C15.6095 18.5 16.1182 18.2893 16.4933 17.9142C16.8684 17.5391 17.0791 17.0304 17.0791 16.5V14.5M4.0791 8.5L9.0791 13.5M9.0791 13.5L14.0791 8.5M9.0791 13.5V1.5"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default DownloadIcon;
