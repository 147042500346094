import { media, styled } from '@nx-kit/styling';
import Icon from 'components/atoms/Icon/Icon';

export const AvailabilityRow = styled.div`
  display: flex;
`;

export const PriceRow = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  margin-bottom: 30px;

  ${media('md')} {
    margin-bottom: 10px;
    flex-direction: row;
    align-items: flex-end;
  }
`;

export const ButtonsRow = styled.div`
  display: flex;
  gap: 16px;
  margin-top: 20px;
  margin-bottom: 30px;
  flex-direction: column;

  ${media('md')} {
    flex-direction: row;
    align-items: flex-end;
  }
`;

export const StatusStyled = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  margin-right: 50px;
  margin-bottom: 34px;
`;

export const StatusLabelStyled = styled.div`
  font-size: 13px;
  line-height: 20px;
`;

export const CourseInfoStyled = styled.div`
  font-size: 13px;
  line-height: 20px;
  color: ${({ theme }) => theme.global.color.gray180};
`;

export const SlotsStyled = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 30px;
  width: 100%;
`;

export const Slot = styled.div`
  align-items: center;
  display: flex;
  width: 100%;
  margin-bottom: 16px;
  ${media('xl')} {
    width: 50%;
  }
`;

export const StyledIcon = styled(Icon)`
  color: ${({ theme }) => theme.global.color.primary};
  margin-right: 7px;
  width: 20px;
  height: 20px;
  min-width: 20px;
  min-height: 20px;
`;

export const PriceStyled = styled.div`
  color: ${({ theme }) => theme.global.color.gray900};
  font-weight: 700;
  font-size: 28px;
  line-height: 38px;
`;

export const ExtraInfoStyled = styled.div`
  font-weight: 400;
  font-size: 13px;
  line-height: 20px;
  color: ${({ theme }) => theme.global.color.gray180};
`;

export const DateContainer = styled.div`
  margin-bottom: 30px;
`;
