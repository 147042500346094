import { ApolloClient, DefaultOptions, HttpLink, InMemoryCache } from '@apollo/client';
import { DomainType, PRISMIC_CUSTOM_TYPES } from 'prismic/config';
import * as prismic from '@prismicio/client';
import { PageProps } from 'types/pages';
import { getDomainFromTags } from './domains';

const { page, header, shared, footer } = PRISMIC_CUSTOM_TYPES;
const api = process.env.NEXT_PUBLIC_PRISMIC_API_ENDPOINT || '';
const token = process.env.NEXT_PUBLIC_PRISMIC_ACCESS_TOKEN || '';
const repoName = process.env.NEXT_PUBLIC_PRISMIC_REPO_NAME || '';

export const client = prismic.createClient(api, { accessToken: token });

export type FetchDocumentContentArgs = {
  lang: string;
  isValidPath: boolean;
  uid: string;
  domain: DomainType;
  pageType?: keyof typeof PRISMIC_CUSTOM_TYPES;
};

export const fetchDocumentContent = async ({
  lang,
  isValidPath,
  uid,
  domain,
  pageType = 'page',
}: FetchDocumentContentArgs): Promise<Omit<
  PageProps,
  'documentRelations' | 'sharedData'
> | null> => {
  try {
    if (isValidPath) {
      const [data, headerData, footerData] = await Promise.all([
        client.getByUID(PRISMIC_CUSTOM_TYPES[pageType], uid || domain, { lang }),

        client.getFirst({
          lang,
          predicates: [
            prismic.predicate.at('document.tags', [domain]),
            prismic.predicate.at('document.type', header),
          ],
        }),

        client.getFirst({
          lang,
          predicates: [
            prismic.predicate.at('document.tags', [domain]),
            prismic.predicate.at('document.type', footer),
          ],
        }),
      ]);

      const tags = data?.tags;
      const taggedDomain = getDomainFromTags(tags);

      /*
       * allow show content in both domains;
       * if you want to restrict content by certain domain, use
       * the next line
       * ``
       * if (data && taggedDomain === domain) {
       * ``
       * so are content pages restricted by the first prismic domain-tag
       * */

      if (data) {
        return {
          data,
          header: headerData,
          footer: footerData,
          domain: taggedDomain,
          type: page,
        };
      }
    }
    return null;
  } catch (e: any) {
    console.error(e);
    return null;
  }
};

const defaultOptions: DefaultOptions = {
  watchQuery: {
    fetchPolicy: 'no-cache',
    errorPolicy: 'ignore',
  },
  query: {
    fetchPolicy: 'no-cache',
    errorPolicy: 'all',
  },
};

export const graphQlClient = new ApolloClient({
  link: new HttpLink({
    uri: prismic.getGraphQLEndpoint(repoName),
    // @ts-ignore -> apollo client types issue
    fetch: client.graphQLFetch,
    useGETForQueries: true,
  }),
  cache: new InMemoryCache(),
  defaultOptions: defaultOptions,
});

export const fetchDocuments = async ({
  pageType = 'page',
  lang,
}: {
  pageType: keyof typeof PRISMIC_CUSTOM_TYPES;
  lang?: string;
}) => {
  try {
    return await client.getAllByType(PRISMIC_CUSTOM_TYPES[pageType], { lang });
  } catch (e) {
    return [];
  }
};

export const fetchSharedData = async (lang?: string) => {
  try {
    return await client.getSingle(shared, { lang });
  } catch (e) {
    return null;
  }
};
