/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from 'react';
import { IconMap, IconType, CommonIconProps } from './iconMap';

type IconProps = CommonIconProps & {
  name: IconType;
};

const Icon = ({ className, name }: IconProps) => {
  const MappedIcon = IconMap[name];
  useEffect(() => {
    if (!MappedIcon) {
      console.warn(`Icon with name "${name}" was not found`);
    }
  }, [MappedIcon]);

  if (!MappedIcon) {
    return null;
  }

  return <MappedIcon className={className} />;
};

export default Icon;
