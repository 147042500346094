import { Flex } from '@nx-kit/flex';
import { css, media, styled } from '@nx-kit/styling';
import { RichText } from 'components/atoms/RichText';

export const Wrapper = styled(Flex)`
  width: 100%;
  flex-wrap: wrap;
  gap: 25px;
`;

export const StyledRichText = styled(RichText)`
  text-decoration: underline;
  margin-left: 12px;
  font-size: ${({ theme }) => theme.global.fontSize.small}px;
  display: block;
`;

export const ChannelStyling = css`
  cursor: pointer;
  color: ${(props) => props.theme.global.color.gray900};
  transition: color 0.5s ease-out;
  display: flex;
  align-items: center;

  &:hover {
    color: ${(props) => props.theme.global.color.secondary};
  }

  svg {
    max-width: 20px;
    max-height: 20px;
    width: 100%;
    height: 100%;
    ${media('xxl')} {
      max-width: 16px;
      max-height: 16px;
    }
  }
`;

export const Channel = styled.button`
  ${ChannelStyling};
`;
