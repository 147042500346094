import { SVGIconProps } from './Icons.types';

const PhoneIcon: React.FC<SVGIconProps> = ({ className }) => (
  <svg
    className={className}
    width="25"
    height="25"
    viewBox="0 0 25 25"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M7.1856 5.21436H10.715L12.4797 9.62612L10.2738 10.9496C11.2188 12.8657 12.7695 14.4165 14.6856 15.3614L16.0091 13.1555L20.4209 14.9202V18.4496C20.4209 18.9177 20.235 19.3665 19.904 19.6975C19.5731 20.0284 19.1242 20.2144 18.6562 20.2144C15.2144 20.0052 11.9681 18.5436 9.52987 16.1054C7.09164 13.6672 5.63006 10.4209 5.4209 6.97906C5.4209 6.51103 5.60682 6.06217 5.93777 5.73123C6.26872 5.40028 6.71758 5.21436 7.1856 5.21436"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default PhoneIcon;
