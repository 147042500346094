type Props = {
  className?: string;
};

const MapCursor: React.FC<Props> = ({ className }) => (
  <svg
    className={className}
    width="18"
    height="18"
    viewBox="0 0 25 25"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M12.9209 14.1896C14.3706 14.1896 15.5459 13.027 15.5459 11.5928C15.5459 10.1587 14.3706 8.99609 12.9209 8.99609C11.4712 8.99609 10.2959 10.1587 10.2959 11.5928C10.2959 13.027 11.4712 14.1896 12.9209 14.1896Z"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />

    <path
      d="M17.8708 16.4899L14.1581 20.1625C13.83 20.4868 13.3852 20.6689 12.9213 20.6689C12.4575 20.6689 12.0127 20.4868 11.6845 20.1625L7.97103 16.4899C6.99209 15.5214 6.32545 14.2876 6.05538 12.9444C5.78531 11.6011 5.92396 10.2088 6.45378 8.94354C6.9836 7.67826 7.88081 6.5968 9.03195 5.83593C10.1831 5.07506 11.5364 4.66895 12.9209 4.66895C14.3054 4.66895 15.6587 5.07506 16.8099 5.83593C17.961 6.5968 18.8582 7.67826 19.388 8.94354C19.9178 10.2088 20.0565 11.6011 19.7864 12.9444C19.5164 14.2876 18.8497 15.5214 17.8708 16.4899V16.4899Z"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default MapCursor;
