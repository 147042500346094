import { useContext } from 'react';
import { ThemeProvider } from '@nx-kit/styling';
import { Theme } from 'themes/themeTypes';
import { defaultTheme } from 'themes/default';
import ballenbergkurseTheme from 'themes/ballenbergkurse';
import { DomainContext } from 'contexts/domain/DomainContext';
import { DatePickerGlobalStyle } from 'components/molecules/DatePicker';
import BasicCSS from './css/BasicCSS';
import FontsCSS from './css/FontsCSS';

const getTheme = (isCourse: boolean): Theme => {
  return isCourse ? ballenbergkurseTheme : defaultTheme;
};

const StylesWrapper: React.FC<{ children?: React.ReactNode }> = ({ children }) => {
  const {
    computed: { isCourse },
  } = useContext(DomainContext);

  return (
    <ThemeProvider theme={getTheme(isCourse)}>
      <>
        <BasicCSS />
        <FontsCSS />
        <DatePickerGlobalStyle />
        {children}
      </>
    </ThemeProvider>
  );
};

export default StylesWrapper;
