import get from 'lodash/get';
import useTranslation from 'next-translate/useTranslation';
import Link from 'components/atoms/Link/Link';
import { CourseInfoDownloadButtonProps } from './CourseInfoDownloadButton.types';

export const CourseInfoDownloadButton: React.FC<CourseInfoDownloadButtonProps> = ({
  className,
  data,
}) => {
  const { t } = useTranslation('common');

  const pdfCmsItem = get(data, 'data.kursinformationen_pdf', null);

  if (!pdfCmsItem || !pdfCmsItem.url) {
    return <></>;
  }

  return (
    <Link
      icon="DOWNLOAD"
      skin="secondary"
      className={className}
      href={pdfCmsItem.url}
      target="_blank"
    >
      {t('courseButtons.courseInfos')}
    </Link>
  );
};
