import { createContext, useReducer } from 'react';
import { COURSE_DOMAIN, DEFAULT_DOMAIN, DomainType } from 'prismic/config';

export interface DomainContextProps {
  state: {
    domain: DomainType;
  };
  computed: {
    isCourse: boolean;
  };
  dispatch: React.Dispatch<any>;
}

const initialState = {
  domain: DEFAULT_DOMAIN,
};

const intialComputed = {
  isCourse: false,
};

export const DomainContext = createContext<DomainContextProps>({
  state: initialState,
  computed: intialComputed,
  dispatch: () => null,
});

const domainReducer = (state: any, { type }: any) => {
  // payload
  switch (type) {
    // reducer actions for different states
    default:
      return state;
  }
};

export const DomainStore = ({ children, domain }: any) => {
  const [state, dispatch] = useReducer(domainReducer, {
    ...initialState,
    domain: domain,
  });

  const computed = {
    isCourse: state.domain === COURSE_DOMAIN,
  };

  return (
    <DomainContext.Provider value={{ state, computed, dispatch }}>
      {children}
    </DomainContext.Provider>
  );
};
