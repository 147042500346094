type Props = {
  className?: string;
};

const InfoIcon: React.FC<Props> = ({ className }) => (
  <svg
    className={className}
    width="1em"
    height="1em"
    viewBox="0 0 25 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M12.7637 20C17.182 20 20.7637 16.4183 20.7637 12C20.7637 7.58172 17.182 4 12.7637 4C8.34539 4 4.76367 7.58172 4.76367 12C4.76367 16.4183 8.34539 20 12.7637 20Z"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <line
      x1="12.7949"
      y1="12.2344"
      x2="12.7949"
      y2="15.7344"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
    />
    <line
      x1="12.7949"
      y1="8.26563"
      x2="12.7949"
      y2="8.79688"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
    />
  </svg>
);

export default InfoIcon;
