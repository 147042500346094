type Props = {
  className?: string;
};

const ClockIcon: React.FC<Props> = ({ className }) => (
  <svg
    className={className}
    width="1em"
    height="1em"
    viewBox="0 0 25 25"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M12.9209 20.9517C17.3392 20.9517 20.9209 17.3699 20.9209 12.9517C20.9209 8.53338 17.3392 4.95166 12.9209 4.95166C8.50262 4.95166 4.9209 8.53338 4.9209 12.9517C4.9209 17.3699 8.50262 20.9517 12.9209 20.9517Z"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M12.9209 8.50732V12.9518L15.5876 15.6184"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default ClockIcon;
