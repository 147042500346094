import { GuidePageProps, PageProps } from 'types/pages';
import BreakpointProviderWrapper from './breakpoint/BreakpointProviderWrapper';
import { SharedContentProvider } from './sharedContent/SharedContentContext';
import { DocumentRelationsProvider } from './documentRelations/DocumentRelationsContext';
import { DomainStore } from './domain/DomainContext';
import { HeaderProvider } from 'contexts/header/HeaderContext';

const ContextsWrapper: React.FC<
  Pick<PageProps, 'documentRelations' | 'sharedData' | 'domain'> &
    Pick<GuidePageProps, 'documentRelations' | 'sharedData' | 'domain' | 'guideData'> & {
      children?: React.ReactNode;
    }
> = ({ documentRelations, sharedData, domain, guideData, children }) => {
  return (
    <DomainStore domain={domain} guideData={guideData}>
      <BreakpointProviderWrapper>
        <DocumentRelationsProvider relationData={documentRelations}>
          <SharedContentProvider value={sharedData}>
            <HeaderProvider>{children}</HeaderProvider>
          </SharedContentProvider>
        </DocumentRelationsProvider>
      </BreakpointProviderWrapper>
    </DomainStore>
  );
};

export default ContextsWrapper;
