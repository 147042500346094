import { styled, css, media } from '@nx-kit/styling';
import { ListTheme } from 'themes/types/list.type';
import { Theme } from 'themes/themeTypes';

const responsiveStyles = (breakpoints: ListTheme, item: 'li' | 'ul' | 'ol') => {
  return Object.keys(breakpoints).map((breakpoint) => {
    // @ts-ignore
    const breakpointStyles = breakpoints[breakpoint];
    if (breakpointStyles[item]) {
      const styles = css`
        ${item !== 'li'
          ? css`
              &:not(:first-child) {
                margin-top: ${breakpointStyles[item].marginTop};
              }

              &:not(:last-child) {
                margin-bottom: ${breakpointStyles[item].marginBottom};
              }
            `
          : css`
              margin-top: ${breakpointStyles[item].marginTop};
              margin-bottom: ${breakpointStyles[item].marginBottom};
            `}
        &:not(:first-child) {
          margin-top: ${breakpointStyles[item].marginTop};
        }

        &:not(:last-child) {
          margin-bottom: ${breakpointStyles[item].marginBottom};
        }

        font-size: ${breakpointStyles[item].fontSize};
        color: ${breakpointStyles[item].color};
      `;

      if (breakpoint === 'xs') return styles;
      return css`
        ${media(`${breakpoint}`)} {
          ${styles}
        }
      `;
    }
    return null;
  });
};

// eslint-disable-next-line no-undef
export const UnorderedList = styled.ul<{ theme: Theme }>`
  ${({ theme }) => responsiveStyles(theme.list, 'ul')};
  list-style: ${({ theme }) => theme.list.xs?.ul?.listStyle ?? 'inherit'};
  margin-left: ${({ theme }) => theme.list.xs?.ul?.marginLeft ?? 'inherit'};

  > li {
    padding-left: ${({ theme }) => theme.list.xs?.li?.paddingLeft ?? 'inherit'};
    position: relative;

    &:before {
      content: '';
      background-color: ${({ theme }) => theme.list.xs?.disc?.color ?? 'inherit'};
      width: 0.5em;
      height: 0.5em;
      border-radius: 50%;
      position: absolute;
      top: 0.6em;
      left: -0.72em;
    }

    > ul {
      margin-top: 0;
      margin-left: 1.125em;
      margin-bottom: 0;
    }
  }
`;

// eslint-disable-next-line no-undef
export const OrderedList = styled.ol<{ theme: Theme }>`
  position: relative;
  list-style: ${({ theme }) => theme.list.xs?.ol?.listStyle ?? 'inherit'};
  counter-reset: li-counter;
  ${({ theme }) => responsiveStyles(theme.list, 'ol')};
  padding-left: ${({ theme }) => theme.list.xs?.ol?.paddingLeft ?? 'inherit'};

  li {
    position: relative;
    counter-increment: li-counter;

    &:before {
      color: ${({ theme }) => theme.list.xs?.counter?.color ?? 'inherit'};
      content: counter(li-counter) '. ';
      font-weight: ${({ theme }) => theme.list.xs?.counter?.fontWeight ?? 'inherit'};
      position: absolute;
      text-align: right;
      left: calc(-1 * 2em - 10px - 2px);
      width: 2em;
    }

    > ol {
      margin-top: 0;
      margin-bottom: 0;
      padding-left: 1.56em;
      counter-reset: li-alpha;

      > li {
        counter-increment: li-alpha;

        &:before {
          text-align: left;
          content: counter(li-alpha, lower-alpha) '. ';
          left: calc(-1 * 2em * 0.7 - 2px);
        }
      }
    }
  }
`;

export const ListItem = styled.li`
  ${(props) => responsiveStyles(props.theme.list, 'li')}
`;
