import find from 'lodash/find';
import { DocumentRelationsLang } from 'prismic/getDocumentRelations';

export const getDisplayNameByUID = ({
  uid,
  documentRelationsLang,
}: {
  uid: string;
  documentRelationsLang: DocumentRelationsLang;
}) => {
  if (!uid || !documentRelationsLang) {
    return null;
  }
  const result = find(documentRelationsLang, (entry) => entry.uid === uid);
  if (result && result.display_name) {
    return result.display_name;
  }
  return null;
};
