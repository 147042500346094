import { useRouter } from 'next/router';
import { isEmpty } from 'lodash';
import { CourseRegistrationPageUrl, CourseRegistrationQueryKey } from 'prismic/config';
import useTranslation from 'next-translate/useTranslation';
import getHeadingTag from 'helpers/getHeadingTag';
import getHeadingSkin from 'helpers/getHeadingSkin';
import { CourseInfoDownloadButton } from 'components/molecules/CourseInfoDownloadButton';
import { CourseStateLight } from 'components/atoms/CourseStateLight';
import { Breadcrumb } from 'components/molecules/Breadcrumb';
import Container from 'components/layout/Grid/Container';
import { RichText } from 'components/atoms/RichText';
import Button from 'components/atoms/Button/Button';
import { Heading } from 'components/atoms/Heading';
import { Box, ImageColumn, PictureStyled } from './Hero.shared.styles';
import { HeroShareButtons } from './partials/HeroShareButtons';
import { HeroCourseProps } from './Hero.types';
import {
  AvailabilityRow,
  StatusStyled,
  StatusLabelStyled,
  CourseInfoStyled,
  StyledIcon,
  Slot,
  SlotsStyled,
  PriceRow,
  PriceStyled,
  ExtraInfoStyled,
  ButtonsRow,
} from './HeroCourse.styles';

export const HeroCourse: React.FC<HeroCourseProps> = ({ className, cms, course }) => {
  const { t, lang } = useTranslation('common');
  const router = useRouter();

  const hasImage = !isEmpty(course.image);

  const description = [
    {
      type: 'paragraph',
      text: course.description,
      spans: [],
    },
  ];

  const courseRegistrationButtonText =
    course.status_color === 'red'
      ? 'courseButtons.courseRegistrationWaitingList'
      : 'courseButtons.courseRegistration';

  return (
    <Container className={className}>
      <Box
        isBox={hasImage}
        start={1}
        span={hasImage ? { xs: 12, md: 5 } : { xs: 12, md: 7 }}
        row={hasImage ? { xs: 2, md: 1 } : {}}
      >
        <Breadcrumb />
        <Heading skin={getHeadingSkin('heading1')} elementType={getHeadingTag('heading1')}>
          {course.title}
        </Heading>
        <AvailabilityRow>
          <StatusStyled>
            <CourseStateLight color={course.status_color} />
            <StatusLabelStyled>{t(course.status_label)}</StatusLabelStyled>
          </StatusStyled>
          <CourseInfoStyled>{`${t('courseNr')} ${course._anlassNr}`}</CourseInfoStyled>
        </AvailabilityRow>

        <SlotsStyled>
          <Slot>
            <StyledIcon name="CALENDAR" />
            <span>{course.date}</span>
          </Slot>
          <Slot>
            <StyledIcon name="CLOCK" />
            <span>{course.time}</span>
          </Slot>
          <Slot>
            <StyledIcon name="MAPCURSOR" />
            <span>{course.place}</span>
          </Slot>
          <Slot>
            <StyledIcon name="TEACHER" />
            <span>{course.person}</span>
          </Slot>
        </SlotsStyled>

        <PriceRow>
          <PriceStyled>{course.price_format}</PriceStyled>
          <ExtraInfoStyled>{course.info}</ExtraInfoStyled>
        </PriceRow>

        <RichText rich_text={description} />

        <ButtonsRow>
          <Button
            onPress={() => {
              const queryString = `?${CourseRegistrationQueryKey}=${course._anlassNr}`;
              router.push(`/${lang}${CourseRegistrationPageUrl}${queryString}`, undefined);
            }}
            skin="primary"
          >
            {t(courseRegistrationButtonText)}
          </Button>
          <CourseInfoDownloadButton data={cms} />
        </ButtonsRow>

        <HeroShareButtons />
      </Box>
      {hasImage && (
        <ImageColumn
          start={{ xs: 1, md: 5 }}
          span={{ xs: 12, md: 8 }}
          row={{ xs: 1, md: 1 }}
          isBig={true}
        >
          <PictureStyled objectFit image={course.image} />
        </ImageColumn>
      )}
    </Container>
  );
};
