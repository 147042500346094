import { RichText as PrismicRichText } from 'prismic-reactjs';
import linkResolver from 'prismic/helpers/linkResolver';
import htmlSerializer from 'prismic/helpers/richtextHtmlSerializer';
import { RichTextProps } from './RichText.types';
import { Wrapper } from './RichText.styles';

export const RichText: React.FC<RichTextProps> = ({
  className,
  rich_text,
  textstring = '',
  children,
}) => {
  const Text = rich_text
    ? // @ts-ignore
      PrismicRichText.render(rich_text, linkResolver, htmlSerializer)
    : textstring;

  return (
    <Wrapper className={className}>
      {Text}
      {children}
    </Wrapper>
  );
};
