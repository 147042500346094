import { useContext } from 'react';
import { styled, css, media } from '@nx-kit/styling';
import getSpacing from 'components/organisms/CmsElmtWrapper/helpers/getSpacing';
import { BreakpointContext } from 'contexts/breakpoint/BreakpointContext';
import getDevice from 'helpers/getDevice';

interface ContainerProps {
  background?: string;
  nextSlice?: string;
  sliceName?: string;
  className?: string;
  children?: React.ReactNode;
}

const Wrapper = styled.div<{
  background?: string;
  spacing: number;
  rows: number;
  sliceName?: string;
}>`
  position: relative;
  width: 100%;
  height: 100%;
  padding: 0 ${({ theme }) => theme.grid.sideGap.xs}px;

  display: grid;
  grid-column-gap: ${({ theme }) => theme.grid.gap.xs}px;
  grid-template-columns: repeat(12, [col] minmax(1px, 1fr));

  ${(props) =>
    props.background &&
    css`
      background-color: ${props.background};
    `};
  ${(props) =>
    props.spacing &&
    css`
      margin-bottom: ${props.spacing}px;
    `};

  ${media('md')} {
    padding: 0 ${({ theme }) => theme.grid.sideGap.md}px;
  }
  ${media('xl')} {
    padding: 0 ${({ theme }) => theme.grid.sideGap.xl}px;
  }
  ${media('xxl')} {
    padding: 0 ${({ theme }) => theme.grid.sideGap.xxl}px;
  }
`;

export const colsAmount = 12;

const Container: React.FC<ContainerProps> = ({
  background,
  nextSlice,
  sliceName,
  children,
  className,
}) => {
  const breakpoint = useContext(BreakpointContext);
  const spacingBreakpoint = getDevice(breakpoint, 'smartphone') ? 'xs' : 'xl';

  return (
    // @ts-ignore
    <Wrapper
      background={background}
      spacing={getSpacing(spacingBreakpoint, sliceName || '', nextSlice)}
      sliceName={sliceName}
      className={className}
    >
      {children}
    </Wrapper>
  );
};

export default Container;
