import { PrismicHeadingType } from 'prismic/types';

type Heading = {
  [key in PrismicHeadingType]: SkinType;
};

type SkinType = '800' | '700' | '600' | '500';

const HEADING_MAP: Heading = {
  heading1: '800',
  heading2: '700',
  heading3: '600',
  heading4: '500',
};

const getHeadingSkin = (
  prismicHeadingType: PrismicHeadingType | string | null | undefined
): SkinType => {
  return HEADING_MAP[prismicHeadingType as keyof Heading];
};

export default getHeadingSkin;
