type Props = {
  className?: string;
};

const Loupe: React.FC<Props> = ({ className }) => (
  <svg
    className={className}
    width="19"
    height="18"
    viewBox="0 0 19 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M14.4112 11.8373L14.3141 11.9749L14.4332 12.094L18.4824 16.1426L18.4825 16.1426C18.6011 16.2612 18.6012 16.4537 18.4824 16.5726L17.3441 17.7109C17.2253 17.8297 17.0329 17.8297 16.9141 17.7109L16.914 17.7109L12.8642 13.6617L12.7455 13.543L12.608 13.6394C11.3707 14.5071 9.88362 14.9795 8.36124 14.9795C6.38395 14.9795 4.52789 14.2116 3.13354 12.8172C1.73941 11.4231 0.971484 9.56705 0.971484 7.58975C0.971484 5.61246 1.73941 3.7564 3.13376 2.36206C4.52788 0.967928 6.38394 0.2 8.36124 0.2C10.3385 0.2 12.1946 0.967927 13.5887 2.36226C16.1623 4.93624 16.4751 8.91383 14.4112 11.8373ZM4.70206 11.2489L4.70214 11.249C5.67809 12.2242 6.97801 12.7625 8.36124 12.7625C9.74446 12.7625 11.0444 12.2242 12.0203 11.249L12.0204 11.2489C14.0373 9.23128 14.0373 5.94823 12.0204 3.93063L12.0204 3.9306C11.045 2.95521 9.74501 2.41767 8.36124 2.41767C6.97746 2.41767 5.67748 2.95521 4.70209 3.9306L4.70206 3.93063C2.68514 5.94823 2.68514 9.23128 4.70206 11.2489Z"
      fill="currentColor"
      strokeWidth="0.4"
    />
  </svg>
);

export default Loupe;
