/* eslint-disable no-restricted-imports */
import { SVGIconProps } from './Icons.types';

const MailIconAlternative: React.FC<SVGIconProps> = ({ className }) => (
  <svg
    className={className}
    width="25"
    height="25"
    viewBox="0 0 25 25"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M19.1431 6.71436H6.69868C5.71684 6.71436 4.9209 7.48187 4.9209 8.42864V17.0001C4.9209 17.9468 5.71684 18.7144 6.69868 18.7144H19.1431C20.125 18.7144 20.9209 17.9468 20.9209 17.0001V8.42864C20.9209 7.48187 20.125 6.71436 19.1431 6.71436Z"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M4.9209 8.42844L12.9209 13.5713L20.9209 8.42844"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default MailIconAlternative;
