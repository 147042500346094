import { DomainType, DOMAINS, DEFAULT_DOMAIN } from 'prismic/config';

// eslint-disable-next-line import/prefer-default-export
export const getDomainFromTags = (tags: Array<string>): DomainType => {
  let domain: DomainType | '' = '';
  tags.forEach((tag: any) => {
    if (!domain && DOMAINS.indexOf(tag) >= 0) {
      domain = tag;
    }
  });

  if (!domain) {
    domain = DEFAULT_DOMAIN;
  }
  return domain;
};
