import { css, media, styled } from '@nx-kit/styling';
import { Theme } from 'themes/themeTypes';
import Picture from 'components/atoms/Picture/Picture';
import Column from 'components/layout/Grid/Column';
import { Heading } from 'components/atoms/Heading';

export const PictureStyled = styled(Picture)`
  min-height: 0;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
`;

export const Box = styled(Column)<{ isBox: boolean; theme: Theme }>`
  position: relative;
  z-index: ${({ theme }) => theme.global.zIndex.one};
  ${({ isBox, theme }) =>
    isBox
      ? css`
          align-self: center !important;
          padding: 24px 28px;
          background-color: ${theme.global.color.white};
          box-shadow: 0 3px 12px rgba(0, 0, 0, 0.12);
          border-radius: 8px;

          ${media('md')} {
            margin: 40px 0;
            padding: 44px 50px 56px;
            box-shadow: 0 10px 20px -13px rgba(0, 0, 0, 0.2);
          }
        `
      : css`
          padding: 0;
        `}
`;

export const ImageColumn = styled(Column)<{ isBig: boolean; theme: Theme }>`
  position: relative;
  padding-bottom: 60%;
  overflow: hidden;
  border-radius: 0;
  margin: 0 -${({ theme }) => theme.grid.sideGap.xs}px -70px;

  ${media('md')} {
    border-radius: 8px;
    margin: 0;
  }

  ${({ isBig, theme }) =>
    isBig &&
    css`
      ${media('md')} {
        margin-right: -${theme.grid.sideGap.md}px;
        border-radius: 8px 0 0 8px;
      }

      ${media('xl')} {
        margin-right: -${theme.grid.sideGap.xl}px;
      }

      ${media('xxl')} {
        margin-right: -${theme.grid.sideGap.xxl}px;
      }
    `}
`;

export const Subheadline = styled(Heading)<{ theme: Theme }>`
  display: block;
  margin: 0 0 5px;
  ${media('md')} {
    margin: 0 0 15px;
  }
  color: ${({ theme }) => theme.global.color.primary};
`;
