import { styled } from '@nx-kit/styling';
import Logo from 'components/atoms/Logo/Logo';
import Link from 'components/atoms/Link/Link';

export const LogoStyled = styled(Logo)`
  width: 140px;
  margin-right: auto;
  margin-top: 20px;
`;

export const LinkStyled = styled(Link)`
  margin-top: 30px;
`;

export const Wrapper = styled.div`
  display: flex;
  margin: 0 auto;
  max-width: 800px;
  height: 100vh;
  width: 100vw;
  flex-direction: column;
  align-items: center;
  font-family: Arial, Helvetica, sans-serif;
`;

export const HStyled = styled.h1`
  margin-top: 40px;
  font-size: 80px;
  font-weight: bold;
`;

export const AStyled = styled.a`
  margin-top: 20px;
`;
